// @flow
import { useEffect, useRef, useState, useCallback } from 'react';
import { SvgIcon } from './SvgIcon';
import { Pill } from './Pill';
import { cn } from './lib/classNames';
import { useAnimAutoFocus } from './lib/autofocus';
import { Tooltip } from './Tooltip';
import { Spinner } from './Spinner';

import type { Icon } from '../../assets/icons';

type FocusHoverHook = {
    ref: { current: ?HTMLElement },
    classNames: Object,
};

export const useFocusHoverStyle = (prefix: string, focusSelectAll: ?boolean): FocusHoverHook => {
    const [states, setStates] = useState({ focus: false, hover: false });
    const ref = useRef<?HTMLElement>(null);

    useEffect(() => {
        const { current } = ref;
        if (current) {
            const focus = (event: FocusEvent) => {
                setStates((s) => ({ ...s, focus: true }));
                if (focusSelectAll && (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement)) event.target.select();
            }
            const blur = () => setStates((s) => ({ ...s, focus: false }));
            const mouseOver = () => {

                setStates((s) => ({ ...s, hover: true }));
            }
            const mouseOut = () => setStates((s) => ({ ...s, hover: false }));

            if (document.activeElement === current) {
                setStates((s) => ( {...s, focus: true }));
            }

            current.addEventListener('focus', focus);
            current.addEventListener('blur', blur);
            current.addEventListener('mouseover', mouseOver);
            current.addEventListener('mouseout', mouseOut);

            return () => {
                current.removeEventListener('focus', focus);
                current.removeEventListener('blur', blur);
                current.removeEventListener('mouseover', mouseOver);
                current.removeEventListener('mouseout', mouseOut);
            }
        }
    }, [ref, focusSelectAll]);

    const classNames = {
        [prefix + '--has-focus']: states.focus,
        [prefix + '--has-hover']: states.hover,
    };

    return { ref, classNames };
}

export type TextInputSize = 'default' | 'sm' | 'xs';

type TextInputProps = {
    value: string | number,
    onChange: (value: string) => void,
    id?: string,
    size?: TextInputSize,
    label?: ?string,
    hint?: React$Node,
    preIcon?: Icon,
    preText?: string,
    postText?: ?React$Node,
    postIcon?: ?Icon,
    helperText?: ?string,
    errorText?: ?string,
    optional?: boolean,
    type?: ?string,
    disabled?: boolean,
    autoFocus?: boolean,
    autoComplete?: string,
    focusSelectAll?: boolean,
    autoPopulated?: boolean,
    readOnly?: ?boolean,
    +placeholder?: ?string,
    borderless?: ?boolean,

    inputMode?: string,
    pattern?: string,
    +step?: string|number,
    +ellipsis?: boolean,
    +tabIndex?: ?number | ?string,
    +skipFocusOnPostInlayClick?: boolean,

    className?: ?string,

    onPostInlayClick?: ?() => any,
    +onKeyDown?: (e: SyntheticKeyboardEvent<HTMLInputElement>) => any,
    +onMouseEnter?: (e: MouseEvent) => void,
    +onMouseLeave?: (e: MouseEvent) => void,
    +onFocus?: (e: FocusEvent) => void,
    +onBlur?: (e: FocusEvent) => void,
}

export const TextInput = (props: TextInputProps): React$Node => {

    const {
        value, label, hint, size, optional, disabled, type,
        preIcon, preText,
        postText, postIcon,
        errorText, helperText,
        onChange, onPostInlayClick,
        borderless,
        className,
        autoFocus,
        focusSelectAll,
        autoPopulated,
        ellipsis,
        onMouseEnter, onMouseLeave,
        skipFocusOnPostInlayClick,
        ...rest
    } = props;

    const [stillAutoPopulated, setStillAutoPopulated] = useState(autoPopulated);

    const { ref: inputRef, classNames: hoverClasses } = useFocusHoverStyle('c-input', focusSelectAll);

    let classes = {
        'c-input': true,
        'c-input--has-pre-icon': !!preIcon,
        'c-input--has-pre-text': !!preText,
        'c-input--has-post-text': !!postText,
        'c-input--has-post-icon': !!postIcon,
        'c-input--has-post-action': !!onPostInlayClick,
        'c-input--has-error': !!errorText,
        'c-input--has-label': !!label,
        'c-input--borderless': !!borderless,
        'c-input--completed': !!value,
        'c-input--ellipsis': !!ellipsis,
        'c-input--optional': !!optional,
        'c-input--is-disabled': (disabled === true),
        'c-input--auto-populated': (autoPopulated === true && stillAutoPopulated),
        'c-input--sm': (size === 'sm'),
        'c-input--xs': (size === 'xs'),
        ...hoverClasses,
    }

    useAnimAutoFocus(autoFocus, disabled, focusSelectAll, inputRef);

    const inputTooltipProps = disabled ? null : { onMouseEnter, onMouseLeave };
    const overlayTooltipProps = disabled ? { onMouseEnter, onMouseLeave } : null;

    const current = inputRef.current;
    const onComponentClick = useCallback((e: MouseEvent) => {
        if (
            skipFocusOnPostInlayClick
            && e.target instanceof Element
            && e.target.closest('.c-input__inlay') != null
        ) {
            return;
        }

        if (current) {
            current.focus();
        }
    }, [current, skipFocusOnPostInlayClick]);

    return (
        <div className={cn(classes) + ' ' + (className || '')} onClick={onComponentClick}>
            <div className='c-input__outline'></div>
            <div className='c-input__content' {...overlayTooltipProps}>
                {props.label
                    ? <label className='c-input__label'>
                        {label}
                        {optional ? <span> (optional)</span> : null}
                        {hint
                            ? <Tooltip
                                placement='top'
                                overlay={hint}>
                                <Pill>?</Pill>
                            </Tooltip>
                            : null
                        }
                    </label>
                    : null
                }
                {preIcon
                    ? <SvgIcon className='c-input__inlay c-input__inlay--pre-icon' svg={preIcon} />
                    : null}
                {preText
                    ? <div className='c-input__inlay c-input__inlay--pre-text'>
                        {preText}
                    </div>
                    : null}
                <input
                    type={type || 'text'}
                    className='c-input__control'
                    ref={inputRef}
                    placeholder={label}
                    spellCheck={false}
                    value={value}
                    disabled={disabled === true}
                    onChange={(e) => {
                        setStillAutoPopulated(false);
                        onChange(e.target.value);
                    }}
                    {...inputTooltipProps}
                    {...rest}
                />
                {postText
                    ? <div className='c-input__inlay c-input__inlay--post-text' onClick={onPostInlayClick}>{postText}</div>
                    : null}
                {postIcon && postIcon !== 'spinner'
                    ? <SvgIcon className='c-input__inlay c-input__inlay--post-icon' svg={postIcon} onClick={onPostInlayClick} />
                    : null}
                {postIcon && postIcon === 'spinner'
                    ? <Spinner className='c-input__inlay c-input__inlay--post-icon mt-2' size='sm' onClick={onPostInlayClick} />
                    : null}
            </div>
            {helperText && !errorText
                ? <div className='c-input__helper-text'>{helperText}</div>
                : null
            }
            {errorText
                ? <div className='c-input__helper-text'>{errorText}</div>
                : null}
        </div>
    );
};

type PasswordInputProps = {
    +value: string,
    +onChange: (value: string) => any,
    +label: string,
    +id: string,
    +helperText?: ?string,
    +autoFocus?: boolean,
    +errorText?: ?string,
    +autoComplete?: string,
    +disabled?: boolean,
}

export const PasswordInput = ({ value, onChange, helperText, autoFocus, autoComplete, label, id, errorText, disabled }: PasswordInputProps): React$Node => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <TextInput
            label={label}
            id={id}
            value={value}
            autoFocus={autoFocus}
            onChange={onChange}
            autoComplete={autoComplete}
            helperText={helperText}
            errorText={errorText}
            type={showPassword ? 'text' : 'password'}
            postIcon={showPassword ? 'hide' : 'show'}
            onPostInlayClick={() => setShowPassword(!showPassword)}
            focusSelectAll={true}
            disabled={disabled}
            className='w-full'
        />
    )
}