// @flow
import { Spinner } from '../element/Spinner';


export const PreLoginCheckBase = (): React$Node => {
    return (
        <div className='flex h-full w-full items-center justify-center text-gray-500'>
           <Spinner className='mt-48' />
        </div>
    )
}