// @flow

import { ResourceSelector } from '../../common/ResourceSelector';
import { idNameSort, relationshipSort, } from '../../element/Sort';
import { AccountSearch, accountSearchDef } from './AccountSearch';
import { Table, Td, Th, Tr } from '../../element/Table';
import { NoResourcesTr, NoMatchingResourceTr, NoResourcesTrProps } from '../../element/NoResourceMessages';
import { Button, TooltipButton } from '../../element/Button';
import { Tooltip } from '../../element/Tooltip';
import { ResourceChip } from '../../element/Chip';
import { Pill } from '../../element/Pill';
import { Link } from 'react-router-dom';
import { DropdownButton } from '../../element/DropdownButton';
import { RC_CACHED } from '../../../state/resource/type';
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries } from '../../element/Skeleton';
import { sortAccountsByHistory } from '../../../state/auth/AccountHistory';
import { useCurrentAccountId } from '../../hoc/lib';
import { useDialog } from '../../element/Dialog';
import { history } from '../../../lib/history';
import { CreateAccountConfirmDialog } from '../account/DIalogs';
import { useSelector } from 'react-redux';
import { isAbleToAcceptCollaboration } from '../../../lib/user-collaborations';

import type { BbCollectedAccount, } from '../../../api/type.acc';
import type { ResourceSelectorTableProps } from '../../common/ResourceSelector';
import type { PendingCollaborations, } from '../../hoc/Collaboration';
import type { BbCollaboration, BbUser } from '../../../api/type';
import type { SortFields } from '../../element/Sort';
import type { CloudGuiState } from '../../../state/cloudgui';
import type { NoMatchingResourceTrProps } from "../../element/NoResourceMessages";

export const accountSortFields: SortFields<BbCollectedAccount> = ({
    _default: idNameSort < BbCollectedAccount > (),
    name: idNameSort < BbCollectedAccount > (),
    relationship: relationshipSort(),
});

export const selectorSortFields: SortFields<BbCollectedAccount> = ({
    _default: (a: BbCollectedAccount, b: BbCollectedAccount): number => {
        let res = sortAccountsByHistory(a, b);
        if (res === 0) res = ((a.name || a.id).localeCompare(b.name || b.id));

        return res;
    },
});

export type AccountListProps = {
    currUserId: ?string,
    onLeaveDialogShow: (account: BbCollectedAccount) => void,
    pendingCollaborations: PendingCollaborations,
    confirmAcceptDialog: (collab: BbCollaboration) => void,
    confirmDeclineDialog: (collab: BbCollaboration) => void,
    enableTwoFactorDialog: () => void;
}

export type AccountInviteRowProps = {
    collab: BbCollaboration,
    currentUser: ?BbUser,
    acceptCollab: (collab: BbCollaboration) => void,
    rejectCollab: (collab: BbCollaboration) => void,
};

export function AcceptInviteTooltip({ collab, user, enableTwoFactorDialog, requestEmailVerification, showButtons }) {
    const messages = [];
    const twoFactorRequired = collab?.tfa_required && user?.['2fa'].enabled === false;
    const verificationRequired = !user?.email_verified;
    let button = null;

    if (twoFactorRequired || verificationRequired ) {
        messages.push('Before you can accept this invitation, you\'ll need to ');
    }

    if (verificationRequired) {
        messages.push('verify your email address');
        if (twoFactorRequired) { messages.push(' and ')};
        button = <TooltipButton onClick={() => requestEmailVerification()}>Resend Verification Email</TooltipButton>
    }

    if (twoFactorRequired) {
        messages.push('enable Two-Factor Authentication.')
        button = button || <TooltipButton onClick={() => enableTwoFactorDialog()}>Enable Two-Factor</TooltipButton>;
    }

    return (
        <>
            {messages.length > 0 ? <p>{messages}</p> : null}
            {button && showButtons ? <p>{button}</p> : null}
        </>
    );
}

export const AccountInviteRow = ({ collab, currentUser, enableTwoFactorDialog, requestEmailVerification, acceptCollab, rejectCollab }: AccountInviteRowProps): React$Node => {
    if (!currentUser) { return null; }

    const isTfaRequired = collab.tfa_required;

    // Whilst two-factor initially limits accepting a collaboration it
    // is due to expand to include verified email addresses so this
    // should be generalised.
    const isAcceptable = isAbleToAcceptCollaboration(currentUser, collab);

    return (
        <Tr highlighted={true}>
            <Td resourceName={true}>
                <ResourceChip resource={collab.account} />
            </Td>
            <Td>Collaborator <Pill>Pending</Pill></Td>
            <Td data-two-factor-required={isTfaRequired}>
                {isTfaRequired ? 'Yes' : 'No'}
            </Td>
            <Td actions={true}>
                <div className='space-x-2'>
                    <Button
                        size='sm'
                        kind='secondary'
                        color='red'
                        onClick={() => { rejectCollab(collab); }}
                    >
                        Decline Invitation
                    </Button>
                    <Tooltip
                        overlay={
                            isAcceptable
                                ? null
                                : <AcceptInviteTooltip
                                    collab={collab}
                                    user={currentUser}
                                    enableTwoFactorDialog={enableTwoFactorDialog}
                                    requestEmailVerification={requestEmailVerification}
                                    showButtons={true}
                                />
                            }
                    >
                        <Button
                            size='sm'
                            kind='primary'
                            disabled={!isAcceptable}
                            onClick={() => { acceptCollab(collab); }}
                        >
                            Accept Invitation
                        </Button>
                    </Tooltip>
                </div>
            </Td>
        </Tr>
    );
};

export type AccountRowProps = {
    acc: BbCollectedAccount,
    currAccountId: ?string,
    currUserId: ?string,
    onLeaveDialogShow: (account: BbCollectedAccount) => void,
};

export const AccountRow = ({ acc, currAccountId, currUserId, onLeaveDialogShow }: AccountRowProps): React$Node => {
    return (
        <Tr>
            <Td resourceName={true}>
                <ResourceChip
                    resource={acc}
                    current={acc.id === currAccountId}
                    link={`/accounts/${acc.id}/`}
                />
            </Td>
            <Td>{acc.owner.id === currUserId ? 'Owner' : 'Collaborator'}</Td>
            <Td className="two-factor-state">{acc.tfa_required ? 'Yes' : 'No'}</Td>
            <Td actions={true}>
                <DropdownButton
                    postIcon={'ellipsis-horizontal'}
                    kind='tertiary'
                    color='grey'
                    dropdown={
                        <>
                            <Link to={`/accounts/${acc.id}/settings/`}>
                                <Button size='sm' kind='tertiary' className='w-full'>Account Settings</Button>
                            </Link>
                            <hr />
                            {acc.owner.id === currUserId
                                ? <Link to={`/accounts/${acc.id}/settings/close/`}>
                                    <Button size='sm' color='red' kind='tertiary' className='w-full'>Close Account</Button>
                                </Link>
                                : <Button size='sm' color='red' kind='tertiary' onClick={() => onLeaveDialogShow(acc)}>Leave Account</Button>
                            }
                        </>
                    }
                />
            </Td>
        </Tr>
    );
};

type AccountTableProps = {
    resourceSelector: ResourceSelectorTableProps<BbCollectedAccount>,
    accountList: AccountListProps,
}

export function NoAccountsTr({ totalCount, inviteCount, ...props }: NoResourcesTrProps & { inviteCount: number }) {
    return <NoResourcesTr totalCount={totalCount + inviteCount} {...props} />
}

export function NoMatchingAccountTr({ inviteCount, ...props}: NoMatchingResourceTrProps & { inviteCount: number }) {
    if (inviteCount > 0) {
        return null;
    }

    return <NoMatchingResourceTr {...props} />
}

const AccountTable = ({ resourceSelector, accountList, }: AccountTableProps) => {
    const { items, Th: SortTh, search, status, totalCount, } = resourceSelector;
    const {
        currUserId,
        onLeaveDialogShow,
        confirmAcceptDialog,
        confirmDeclineDialog,
        pendingCollaborations,
        enableTwoFactorDialog,
        requestEmailVerification,
    } = accountList;
    const currAccountId = useCurrentAccountId();
    const currentUser = useSelector < CloudGuiState, BbUser | null > ((state) => state.Auth.currUser);

    const { collaborations, } = pendingCollaborations;

    return (
        <Table>
            <thead>
                <Tr>
                    <SortTh field='name'>Account</SortTh>
                    <SortTh field='relationship'>My role</SortTh>
                    <Th>
                        Two-Factor Required
                        <Tooltip overlay='Does this Account require that all users have Two-Factor Authentication?'>
                            <Pill>?</Pill>
                        </Tooltip>
                    </Th>
                    <Th actions={true}>&nbsp;</Th>
                </Tr>
            </thead>
            <tbody>
                {status === RC_CACHED
                    ? collaborations.map(collab =>
                        <AccountInviteRow
                            collab={collab}
                            currentUser={currentUser}
                            acceptCollab={confirmAcceptDialog}
                            rejectCollab={confirmDeclineDialog}
                            enableTwoFactorDialog={enableTwoFactorDialog}
                            requestEmailVerification={requestEmailVerification}
                        />
                    )
                    : null}
                {items.map((acc: BbCollectedAccount) =>
                    <AccountRow
                        acc={acc}
                        currUserId={currUserId}
                        currAccountId={currAccountId}
                        onLeaveDialogShow={onLeaveDialogShow}
                    />
                )}
                {status !== RC_CACHED
                    ? <SkeletonListLoadingEntries>
                        <Tr>
                            <Td resourceName={true}><SkeletonChip /></Td>
                            <Td><SkeletonBar size='md' /></Td>
                            <Td><SkeletonBar size='md' /></Td>
                            <Td actions={true}><SkeletonBar size='sm' /></Td>
                        </Tr>
                    </SkeletonListLoadingEntries>
                    : null
                }
                <NoMatchingAccountTr
                    search={search}
                    inviteCount={collaborations.length}
                    kind='account'
                    colSpan={3}
                />
                <NoAccountsTr
                    status={status}
                    totalCount={totalCount}
                    inviteCount={collaborations.length}
                    context='list'
                    kind='account'
                    colSpan={3}
                />
            </tbody>
        </Table>
    );
};


export const AccountList = (props: AccountListProps): React$Node => {
    const addConfirmDialog = useDialog < null > ([{
        label: 'Create Account',
        kind: 'primary',
        onSelect: () => {
            history.push('/signup/account?additional');
        }
    }]);

    return (
        <>
            <CreateAccountConfirmDialog dialog={addConfirmDialog} />
            <ResourceSelector
                kind='account'
                context='list'
                render={(listProps: ResourceSelectorTableProps<BbCollectedAccount>) =>
                    <AccountTable
                        resourceSelector={listProps}
                        accountList={props}
                    />
                }
                searchDef={accountSearchDef}
                searchComponent={AccountSearch}
                sortFields={accountSortFields}
                addButton={{
                    url: '/signup/account?additional', // redundant with the dialog, but saves the typing hassle...
                    resourceName: 'New Account',
                }}
                addConfirmDialog={addConfirmDialog}
            />
        </>
    );
}