import { SecretDisplay } from "../../../element/SecretDisplay";
import { Dialog } from "../../../element/Dialog";
import { Notice } from "../../../element/Notice";
import { useSecretDisplayDialog } from "../../../hoc/Secret";

export function TwoFactorBackupCodesDialog({ userId }: { userId: string }) {
    const twoFactorBackupCodes = useSecretDisplayDialog(userId);

    return (
        <Dialog
            title='Two-Factor Backup Codes'
            dialog={twoFactorBackupCodes.dialog}
            render={() =>
                <div>
                    <p>Two-Factor Authentication is now enabled.</p>

                    <Notice type='warning' icon='warning-fill' className='mb-2'><b>Important</b> - Please store the backup codes below
                    securely in case you lose access to your 2FA device</Notice>

                    <SecretDisplay
                        secret={twoFactorBackupCodes.secret}
                    />
                </div>
            }
        />
    )
}