// @flow

import { Router } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import { Layout } from './Layout';
import { SignupSidebar } from './Sidebar';
import { AuthedSignup } from "./AuthedSignup";
import { AccountSignUp } from '../section/signup/AccountSignUp';
import { UserSignUp } from '../section/signup/UserSignUp';
import { StripeWrapper } from '../section/account/edit/StripeWrapper';
import { CollabSignUp } from '../section/signup/CollabSignUp';
import { history } from '../../lib/history';


export const SignUp = (): React$Node => {
    return (
        <Layout
            kind='auth'
            sidebar={<SignupSidebar/>}
            content={
                <Router history={history}>
                    <StripeWrapper>
                    <Switch>
                        <Route
                            path='/signup/next'
                            render={(props) => <AuthedSignup render={() => null} />}
                        />
                        <Route
                            path='/signup/collab'
                            render={(props) => <AuthedSignup render={() => <CollabSignUp {...props} />} />}
                        />
                        <Route
                            path='/signup/:id(account|acc-[a-z0-9]{5})'
                            render={(props) => <AuthedSignup render={() => <AccountSignUp {...props} />} />}
                        />
                        <Route path='/signup' component={UserSignUp} />
                    </Switch>
                    </StripeWrapper>
                </Router>
            }
        />
    );
};

