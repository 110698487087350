// @flow
import { useState } from 'react';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { useCreateResource } from '../../hoc/CreateResource';
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../element/Panel';
import { TextInput } from '../../element/Input';
import { historyBack } from '../../../lib/history';
import { LABELS } from '../../element/ResourceLabels';
import { Dialog, useDialog } from "../../element/Dialog";
import { useCurrentAccount } from "../../hoc/lib";
import { EMAIL_REGEX } from "../../hoc/SignUp";
import { Notice } from '../../element/Notice';
import { Button } from '../../element/Button';


import type { BbCollaboration, BbCollaborationParams, } from '../../../api/type';
import type { Match } from 'react-router-dom';

export const CollaborationCreate = ({ match }: { match: Match }): React$Node => {
    const {
        createResource,
        messages,
        status
    } = useCreateResource<BbCollaboration, BbCollaborationParams>('collab', 'collaboration');
    const [params, setParams] = useState<BbCollaborationParams>({ email: '', role: 'admin', });
    const account = useCurrentAccount();

    const confirmDialog = useDialog([
        {
            label: 'Send Invitation',
            kind: 'primary',
            onSelect: () => createResource(params),
        }
    ]);

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.user.listTitle}
            dialog={
            <Dialog
                title={'Invite Team Member?'}
                dialog={confirmDialog}
                footerLink={<a href='https://www.brightbox.com/docs/reference/collaboration/' rel='noreferrer noopener' target='blank'>
                <Button size='sm' kind='bare' preIcon='info-fill'>Inviting Collaborators</Button></a>}
                render={() => (
                    <>
                        <p>Are you sure you want to invite "{params.email}" to account {account?.id}?</p>
                        <Notice type='warning' icon='warning-fill'>
                            <b>Please Note:</b> inviting a user to your account means they
                            can create, update and delete resources on your account as
                            well as invite other users.
                        </Notice>
                    </>
                )}
            />
            }
            match={match}
            view={
                <Panel>
                    <PanelHeader title='Invite a Team Member'/>
                    <PanelBar>
                        <TextInput
                            value={params.email}
                            onChange={(email: string) => setParams({ ...params, email })}
                            label='Email Address'
                            autoFocus={true}
                            errorText={messages.length ? messages[0] : null}
                        />
                    </PanelBar>
                    <PanelButtonBar
                        primaryButton={{
                            children: 'Send Invitation',
                            onClick: () => {
                                confirmDialog.show();
                            },
                            disabled: !EMAIL_REGEX.test(params.email)
                        }}
                        cancel={historyBack}
                        cacheStatus={status}
                    />
                </Panel>}
        />
    );

};
