// @flow

import { useState, useEffect, useLayoutEffect, useMemo, } from 'react';
import { createPortal } from 'react-dom';
import { to } from 'await-to-js';
import { Button } from './Button';
import { pushEscHandler, removeEscHandler } from '../../lib/history';
import { RC_API_REQUEST, RC_SUCCESS, RC_ERROR } from '../../state/resource/type';
import { Notice } from './Notice';
import FocusTrap from 'focus-trap-react'
import { TextInput } from './Input';
import { Tooltip } from './Tooltip';

import type { MessageHook } from '../hoc/Messages';
import type { StateUpdateFn } from 'react-hooks';

export type DialogAction<Data> = {
    +label: string | (t: ?Data) => string,
    +kind: string,
    +color?: string,
    /*
     * called when this button is clicked.
     * the dialog is closed when the promise resolves,
     * unless result === false
     *
     * there was some code in there that checked "isShowDetailsResourceStatus"
     * on messages passed to the dialog, but due to the closure over execute()
     * in Dialog, it can't have ever worked properly.
     *
     * There is a separate effect that closes the dialog when the messages
     * go to RC_SUCCESS/RC_ERROR.
     *
     * RC_ERROR seems strange, but it must have made sense when I wrote it, so
     * I've not changed it while realising this execute() closure problem.
     */
    +onSelect: ?(t: ?Data) => Promise<boolean>,
    +tooltip?: (t: ?Data) => string | ?React$Node,
    +disabled?: ?(t: ?Data) => boolean,
};

export type DialogState<Data> = {
    data: ?Data,
    setData: (?(((?Data) => ?Data) | Data)) => void,
    visible: boolean,
    show: (t: ?Data) => void,
    hide: () => void,
    actions: Array<DialogAction<Data>>,
    actioned: ?number,
    setActioned: StateUpdateFn<?number>,
};

export const useDialog = <Data>(actions: Array<DialogAction<Data>>, onClose?: () => void): DialogState<Data> => {
    const [data, setData] = useState<?Data>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [actioned, setActioned] = useState<?number>(null);

    const dialogFns = useMemo(() => ({
        show: (t: ?Data) => {
            setVisible(true);
            setData(t);
        },
        hide: () => {
            setVisible(false);
            if (onClose) {
                onClose();
            }
        },
    }), [setVisible, setData, onClose]);

    return useMemo(() => ({
        data,
        visible,
        ...dialogFns,
        setData,
        actions,
        actioned,
        setActioned,
    }), [data, visible, actions, dialogFns, actioned, setActioned]);
};

type ModalTypes = 'default' | 'wide';

export type DialogProps<Data> = {
    +title?: ?React$Node | ?(t: ?Data) => string,
    +footerLink?: ?React$Node,
    +dialog: DialogState<Data>,
    +render: (t: ?Data) => React$Node,
    +type?: ModalTypes,
    +messages?: ?MessageHook<any>,
    +confirmString?: ?string,
    +forceCancelEnabled?: boolean,
};

const dialogRoot = document.getElementById('dialogs') || document.body;

const KEY_CODE_ENTER = 13;

export function Dialog<Data>({ dialog, render, title, footerLink, type, messages, confirmString, forceCancelEnabled, }: DialogProps<Data>): React$Node {
    const status = messages?.status;
    const clear = messages?.clear;
    const { visible, hide, data, actioned, setActioned } = dialog;

    const { body } = document;
    const [confirmInput, setConfirmInput] = useState<string>('');

    useEffect(() => {
        if (visible && clear && (status === RC_SUCCESS || status === RC_ERROR)) {
            hide();
            clear();
        }
    }, [status, visible, hide, clear]);

    useEffect(() => {
        if (!visible) setConfirmInput('');
    }, [visible]);

    if (!dialog.visible || !body) {
        return null;
    }

    const execute = async (action: DialogAction<Data>) => {
        if (!action.onSelect) return;

        let [err, result] = await to(Promise.resolve(action.onSelect(dialog.data)));
        if (!err) {
            if (result !== false) {
                dialog.hide();
            }
        }
    };

    let titleVal = typeof title === 'function'
        ? title(data)
        : title || 'Confirmation';

    return createPortal(
        <Modal
            visible={dialog.visible}
            hide={dialog.hide}
            type={type}
            render={() =>
                <FocusTrap active={dialog.visible}>
                <div className='c-modal__popup'>
                    <div className='c-modal__popup-header'>
                        {typeof titleVal === 'string'
                            ? <h3>{titleVal}</h3>
                            : titleVal
                        }
                        <Button
                            className='c-modal__close'
                            size='sm'
                            kind='tertiary'
                            color='grey'
                            onClick={dialog.hide}
                            preIcon='cross'
                        />
                    </div>

                    <div className='c-modal__popup-body'>
                        {messages && messages.messages
                            ? messages.messages.map((msg, i) => <Notice type='error' key={i}>{msg}</Notice>)
                            : null
                        }

                        {render(dialog.data)}

                        {confirmString
                            ? <div className='mt-3'>
                                Please type <b>{confirmString}</b> below to confirm:<br/>

                                <TextInput
                                    placeholder={confirmString}
                                    value={confirmInput}
                                    onChange={(value) => setConfirmInput(value)}
                                    autoFocus={true}
                                    onKeyDown={(e: SyntheticKeyboardEvent<HTMLInputElement>) => {
                                        if (
                                            e.keyCode === KEY_CODE_ENTER
                                            && dialog.actions.length === 1
                                            && confirmInput === confirmString
                                        ) {
                                            e.preventDefault();
                                            setActioned(0);
                                            execute(dialog.actions[0]);
                                        }
                                    }}
                                    className='mt-2'
                                />
                            </div>
                            : null
                        }
                    </div>

                    <div className='c-modal__popup-footer'>
                        <div className='c-modal__popup-info'>{footerLink}</div>

                        <div className='c-modal__popup-buttons'>
                            <Button
                                size='sm'
                                kind={'tertiary'}
                                color='grey'
                                onClick={dialog.hide}
                                autoFocus={true}
                                disabled={!!messages && messages.status === RC_API_REQUEST && !forceCancelEnabled}
                            >
                                {dialog.actions.length === 0 ? 'Close' : 'Cancel'}
                            </Button>

                            {dialog.actions.map((action, i) => {
                                const label = typeof action.label === 'function'
                                        ? action.label(data)
                                        : action.label;

                                return (
                                    <Tooltip
                                        overlay={
                                            action.tooltip == null
                                                ? null
                                                : typeof action.tooltip === 'function'
                                                    ? action.tooltip(data)
                                                    : action.tooltip
                                        }
                                        key={label}
                                    >
                                        <Button
                                            size='sm'
                                            kind={action.kind}
                                            color={action.color}
                                            onClick={() => {
                                                setActioned(i);
                                                execute(action);
                                            }}
                                            state={messages?.status === RC_API_REQUEST && actioned === i ? 'loading' : null}
                                            disabled={
                                                !!(messages?.status === RC_API_REQUEST && actioned != null && actioned !== i)
                                                || (confirmString != null && confirmInput !== confirmString)
                                                || (typeof action?.disabled == 'function' ? action.disabled(data) : null)
                                                || action.onSelect == null
                                            }
                                            type="button"
                                        >
                                            {label}
                                        </Button>
                                    </Tooltip>
                                );
                            })}
                        </div>

                    </div>
                </div>
                </FocusTrap>
            }
        />,
        dialogRoot
    );
}

export type ModalProps = {
    +visible?: boolean,
    +render: () => React$Node,
    +hide: () => any,
    +type?: ModalTypes,
}

export const Modal = ({ visible, render, hide, type, }: ModalProps): React$Node => {
    const isVisible = (visible == null) || visible;
    let [backdrop, ] = useState(() => {
        const backdrop = document.createElement('div');
        backdrop.className = 'c-page-overlay';
        return backdrop;
    });

    useLayoutEffect(() => {
        const { body } = document;

        if (body == null) {
            return;
        }

        if (isVisible) {
            body.appendChild(backdrop);
            body.classList.add('modal-open');
        }

        return () => {
            body.removeChild(backdrop);
            body.classList.remove('modal-open');
        }
    }, [isVisible, backdrop]);

    useLayoutEffect(() => {
        const listener = () => hide();
        if (isVisible) {
            backdrop.addEventListener('click', listener);
            pushEscHandler(hide);
        }

        return () => {
            if (isVisible) {
                backdrop.removeEventListener('click', listener);
                removeEscHandler(hide);
            }
        }
    }, [hide, isVisible, backdrop]);

    if (!isVisible) {
        return null;
    }

    function closeModal(event: SyntheticUIEvent<HTMLDivElement>) {
        // $FlowFixMe classList is present.
        if (event.target.classList?.contains('c-modal')) {
            hide();
        }
    }

    return (
        <div className='c-modal' onClick={closeModal}>
            <div className={'c-modal__content ' + (type ? 'c-modal__content--' + type : '')}>
                {render()}
            </div>
        </div>
    );
};
