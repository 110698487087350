// @flow
/* global analytics */
import { createLogic } from 'redux-logic';
import { clearConnection, login, request, setAccountId as setApiAccountId } from '../../api/rest';
import { accountsUrl, authUrl, twoFaUrl, userCollaborationsUrl, usersUrl } from '../../api/url';
import { to } from 'await-to-js';
import { setAccountId as setEventStreamAccountId, checkEventConnection, disconnectFaye, } from '../../api/events';
import { history } from '../../lib/history';
import { resourceMetas } from '../../api/adapter';
import { clearTokens } from '../../api/kiosk';
import { RC_CACHED, RC_ERROR, RC_FETCHING } from '../resource/type';
import { twoFactorValidation } from '../../component/hoc/Login';
import { statusToActive } from '../../component/base/def';
import { getLastAccountId, setLastAccountId } from './AccountHistory';
import { toast } from 'react-toastify';

import type { CloudGuiState } from '../cloudgui';
import type { Dispatch } from 'redux';
import type { AuthAction, AuthLogoutAction, AuthSettableField, AuthUserSwitchAccountAction, AuthCookieResetTimeout } from './type';
import type { BbScope, BbUser } from '../../api/type';
import type { ResourceAddFull, ResourceCollectedStatus, ResourceSetCollected } from '../resource/type';
import type { StoreClearAction } from '../type';
import type { BbCollectedAccount } from '../../api/type.acc';
import type { ReduxLogic } from 'redux-logic';

export function setLoginStateFromScopes(dispatch: Dispatch<AuthAction>, rawScopes: ?string): Set<BbScope> {
    if (typeof rawScopes === 'string') {
        const nextScopes: Set<BbScope> = new Set(
            rawScopes.split(',').map(s => ((s.trim(): any): BbScope))
        );

        let payload: AuthSettableField = {
            password: '',
            twoFactorCode: '',
            scopes: nextScopes,
        };

        if (!nextScopes.has('auth:two_factor')) {
            payload = {
                ...payload,
                status: 'valid',
            };
        }

        dispatch({ type: 'AUTH_SET_FIELD', payload });

        return nextScopes;
    } else {
        dispatch({ type: 'AUTH_SET_FIELD', payload: { error: 'No scopes in response', status: 'invalid', } });
        return new Set();
    }
}

export function fetchUserAndAccount(dispatch: Dispatch<AuthAction>, nextScopes: Set<BbScope>) {
    if (nextScopes.has('infrastructure')) {
        dispatch({ type: 'AUTH_GET_ACCOUNT', });
        dispatch({ type: 'AUTH_GET_USER', });
        checkEventConnection();
    }
}

export const TryLoginLogic: ReduxLogic = createLogic({
    'type': ['AUTH_TRY_LOGIN'],
    process: async (deps: { getState: () => CloudGuiState, }, dispatch: Dispatch<AuthAction>, done: () => void) => {
        const { Auth } = deps.getState();

        let err: ?Object, loginResponse: ?Object;

        if (Auth.scopes.has('auth:two_factor')) {
            if (!Auth.twoFactorCode.match(twoFactorValidation)) {
                dispatch({ type: 'AUTH_SET_FIELD', payload: { error: 'Please enter valid OTP code', } });
            } else {
                const formData = new FormData();
                formData.set('otp', Auth.twoFactorCode);

                const twoFaRequest = {
                    method: 'POST',
                    url: twoFaUrl,
                    data: formData,
                };

                dispatch({ type: 'AUTH_SET_FIELD', payload: { error: '', processing: true, } });
                [err, loginResponse] = await to(login(twoFaRequest));
            }
        } else {
            const formData = new FormData();
            formData.set('username', Auth.username);
            formData.set('password', Auth.password);

            const loginRequest = {
                method: 'POST',
                url: authUrl,
                data: formData,
            };

            dispatch({ type: 'AUTH_SET_FIELD', payload: { error: '', processing: true, scopes: new Set() } });
            [err, loginResponse] = await to(login(loginRequest));
        }

        if (err === null && loginResponse === null) {
            dispatch({ type: 'AUTH_SET_FIELD', payload: { error: 'No login request error', } });
        } else if (err) {
            if (err.response && typeof err.response.data === 'object' && 'error_description' in err.response.data) {
                dispatch({ type: 'AUTH_SET_FIELD', payload: { error: err.response.data.error_description, } });
            } else if (err.response && typeof err.response.data === 'string') {
                dispatch({ type: 'AUTH_SET_FIELD', payload: { error: 'Those appear to be incorrect login details - please try again', } });
            } else {
                dispatch({ type: 'AUTH_SET_FIELD', payload: { error: 'Unknown error processing login', } });
            }
        } else if (loginResponse && loginResponse.status !== 200) {
            if (loginResponse.data && typeof loginResponse.data === 'object' && 'error_description' in loginResponse.data) {
                dispatch({ type: 'AUTH_SET_FIELD', payload: { error: loginResponse.data.error_description, } });
            } else if (typeof loginResponse.data === 'string') {
                dispatch({ type: 'AUTH_SET_FIELD', payload: { error: loginResponse.data, } });
            } else {
                dispatch({ type: 'AUTH_SET_FIELD', payload: { error: 'Unknown error processing non-200 login', } });
            }
        } else if (loginResponse && loginResponse.status === 200) {
            const rawScopes = loginResponse.headers['x-oauth-scopes'];
            let nextScopes = setLoginStateFromScopes(dispatch, rawScopes);
            fetchUserAndAccount(dispatch, nextScopes);
        }

        if (deps.getState().Auth.scopes.has('infrastructure')) {
            analytics.track('Login');
        }
        dispatch({ type: 'AUTH_SET_FIELD', payload: { processing: false, } });

        done();
    },
});

export const GetAccountLogic: ReduxLogic = createLogic({
    'type': ['AUTH_GET_ACCOUNT'],
    process: async (deps: { getState: () => CloudGuiState, }, dispatch: Dispatch<AuthAction | ResourceSetCollected<BbCollectedAccount> | ResourceCollectedStatus>, done: () => void) => {
        const { Auth } = deps.getState();

        if (!Auth.scopes.has('infrastructure')) {
            done();
            return;
        }

        dispatch({ type: 'RESOURCE_COLLECTED_STATUS', payload: { kind: 'account', fetched: RC_FETCHING } });

        const [err, accountResponse] = await to(request({
            method: 'GET',
            url: accountsUrl,
            nested: false,
            accountId: false,
        }));

        if (!err && accountResponse.status === 200) {
            if (Array.isArray(accountResponse.data)) {
                const accounts: $ReadOnlyArray<BbCollectedAccount> = accountResponse.data.map(resourceMetas.account.adapt.collected);
                let account: ?BbCollectedAccount = null;

                const uriMatch = history.location.pathname.match(/^\/accounts\/(acc-[a-z0-9]{5})/);

                if (uriMatch) {
                    account = accounts.find(r => r.id === uriMatch[1]);
                }

                if (!uriMatch) {
                    let id = getLastAccountId();
                    if (id !== null) {
                        account = accounts.find(r => r.id === id);
                    }
                }

                if (!uriMatch && account == null && accounts.length > 0) {
                    const active = accounts
                        .filter(x => statusToActive(x.status))
                        .sort((a: BbCollectedAccount, b: BbCollectedAccount) => a.id.localeCompare(b.id))
                    ;
                    account = active.length ? active[0] : accounts[0];
                }

                // NB - when the /signup pages log the user in, via AuthedSignup, if this is a no-account, no-collab
                // user, then we are relying on the uriMatch checks above to all fail, and for no redirect to happen
                // at all. So if these checks are changed, bear the AuthedSignup case in mind...

                dispatch({
                    type: 'RESOURCE_SET_COLLECTED',
                    payload: {
                        kind: 'account',
                        resources: accounts
                    }
                });

                dispatch({ type: 'RESOURCE_COLLECTED_STATUS', payload: { kind: 'account', fetched: RC_CACHED } });
                dispatch({ type: 'AUTH_USER_SWITCH_ACCOUNT', payload: { account, } });
            }
        } else {
            dispatch({ type: 'RESOURCE_COLLECTED_STATUS', payload: { kind: 'account', fetched: RC_ERROR } });
        }

        done();
    },
});

export const GetUserLogic: ReduxLogic = createLogic({
    'type': ['AUTH_GET_USER'],
    process: async (deps: { getState: () => CloudGuiState, }, dispatch: Dispatch<AuthAction>, done: () => void) => {
        const { Auth } = deps.getState();

        if (!Auth.scopes.has('infrastructure')) {
            done();
            return;
        }

        const [err, users] = await to(request({
            method: 'GET',
            url: usersUrl,
        }));

        if (!err && users.status >= 200 && users.status < 300) {
            if (Array.isArray(users.data) && users.data.length) {
                const user = users.data[0];
                dispatch({ type: 'AUTH_SET_FIELD', payload: { currUser: resourceMetas['user'].adapt.collected(user), } });

                // now we have our ID, also fetch the full user - it has some fields we need
                // (ssh keys at least)
                const [fullErr, fullUser] = await to(request({
                    method: 'GET',
                    url: usersUrl + '/' + user.id,
                }));
                if (!fullErr) {
                    dispatch({ type: 'AUTH_SET_FIELD', payload: { currUser: resourceMetas['user'].adapt.full(fullUser.data), } });
                } // else - nothing too bad happens, we re-fetch the user when editing and otherwise things will be mostly fine...
            }
        }

        done();
    },
});

export const SwitchAccountLogic: ReduxLogic = createLogic({
    'type': ['AUTH_USER_SWITCH_ACCOUNT'],
    process: async (deps: { getState: () => CloudGuiState, action: AuthUserSwitchAccountAction }, dispatch: Dispatch<AuthAction>, done: () => void) => {
        let { account, accountId } = deps.action.payload;

        if (typeof accountId === 'string') {
            // see if we can find this account - and redirect if not!
            const { Resource } = deps.getState();
            const found = Resource.account.collected[accountId];

            if (found === null && account === null) {
                history.push('/');
            }

            if (found) {
                account = found;
            }
        }


        if (account) {
            const uriMatch = history.location.pathname.match(/^\/accounts\/(acc-[a-z0-9]{5})\/([^/]+)\/?/);
            // redirect from /accounts/{old-id}/{optional resourceType}(optional /{resourceId}) back to
            // accounts/{new-id}/{optional resourceType}
            if (uriMatch && uriMatch[1] !== account.id) {
                // we store the 'ignoreRouteChange' state here so that the
                // AccountRoute component doesn't spam AUTH_USER_SWITCH_ACCOUNT actions
                // - because the history triggers a re-render, and dispatching actions
                // to the state triggers one.
                history.push('/accounts/' + account.id + '/' + (uriMatch[2] ? uriMatch[2] + '/' : '') + (history.location.hash), { ignoreAccountRoute: true, });
            }

            if (!uriMatch && !history.location.pathname.match(/^\/user|\/playground/)) {
                history.push('/accounts/' + account.id + '/', { ignoreAccountRoute: true, });
            }

            // otherwise - just preserve the URL - presumably it's something like profile or settings.

            dispatch({ type: 'AUTH_SELECT_ACCOUNT', payload: { account: account } });
            setEventStreamAccountId(account.id);
            setApiAccountId(account.id);
            setLastAccountId(account.id);
        } else {
            // the final fallback when there's no accounts available
            dispatch({ type: 'AUTH_SELECT_ACCOUNT', payload: { account: null } });
            const { Resource } = deps.getState();
            const noAccounts = !Resource.account || Object.keys(Resource.account.collected).length === 0;

            // possibly a new user that should be accepting a collaboration; fetch them and see
            let [ , response ] = await to(request({
                url: userCollaborationsUrl,
                accountId: false,
            }));

            const pending = response && response.status === 200
                ? response.data.filter(raw => raw.status === 'pending')
                : [];

            if (pending.length) {
                history.push("/signup/collab");
            } else if (noAccounts && history.location.pathname.startsWith('/signup')) {
                history.push("/signup/account");
            } else if (noAccounts && history.location.pathname ==='/') {
                history.push('/user/');
            }
        }

        // on the first gui load, it needs to wait until fetching accounts + maybe fetching collabs
        // is finished (and therefore the history.push calls above are finished) before rendering,
        // to avoid a flash of the pre-redirect part of the gui.
        dispatch({
            type: 'AUTH_FIRST_SWITCH_COMPLETE',
        });

        done();
    }
});


export const AuthWatchUserChangesLogic: ReduxLogic = createLogic({
    'type': ['RESOURCE_ADD_FULL'],
    process: async (deps: { getState: () => CloudGuiState, action: ResourceAddFull<BbUser, BbUser> }, dispatch: Dispatch<AuthAction>, done: () => void) => {
        const currUserId = (deps.getState().Auth.currUser || { id: ''}).id;

        if (
            deps.action.payload.kind === 'user'
            && deps.action.payload.full.id === currUserId
        ) {
            dispatch({
                type: 'AUTH_SET_FIELD',
                payload: {
                    currUser: deps.action.payload.full,
                },
            });
        }

        done();
    }
});

export const AuthLogoutLogic: ReduxLogic = createLogic({
    type: 'AUTH_LOGOUT',
    process: async (
        deps: { getState: () => CloudGuiState, action: AuthLogoutAction },
        dispatch: Dispatch<AuthAction | StoreClearAction>,
        done: () => void
    ) => {
        const { Auth } = deps.getState();
        const { clearState } = deps.action.payload;

        // if clearState, then the user has actually clicked the "logout"
        // button - it's not just the GUI timing out or some other case.
        // so clear everything.
        if (clearState) {
            await clearConnection();
            dispatch({ type: 'STORE_CLEAR' });

            toast.dismiss();
        }

        const currEmail: ?string = Auth.currUser?.email_address;
        // if this is a 'soft logout', make sure the login state includes
        // the current users email address
        if (!deps.action.payload.clearState && currEmail != null) {
            dispatch({
                type: 'AUTH_SET_FIELD',
                payload: {
                    scopes: new Set(),
                    username: currEmail,
                },
            });
        }

        clearTokens();
        disconnectFaye(clearState);

        if (history.location.pathname.substr(0, 7) === '/logout') history.push('/');

        done();
    }
});

// 5 mins after the soft logout shows, do a full one.
const SOFT_LOGOUT_TO_FULL_TIMEOUT = 5 * 60 * 1000;
// const SOFT_LOGOUT_TO_FULL_TIMEOUT = 2000;

export const AuthWaitForCookieTimeout: ReduxLogic = createLogic({
    type: 'AUTH_COOKIE_RESET_TIMEOUT',
    debounce: true,
    warnTimeout: 0,
    process: (
        deps: { getState: () => CloudGuiState, action: AuthCookieResetTimeout, },
        dispatch: Dispatch<AuthAction | StoreClearAction>,
        done: () => void
    ) => {
        const { Auth } = deps.getState();

        if (Auth.cookieTimeout) {
            Auth.cookieTimeout.clear();
        }

        const timeout = setTimeout(() => {
            dispatch({
                type: 'AUTH_LOGOUT',
                payload: {
                    clearState: false,
                }
            });
            const fullLogoutTimer = setTimeout(() => {
                dispatch({
                    type: 'AUTH_LOGOUT',
                    payload: {
                        clearState: true,
                    }
                });
                done();
            }, SOFT_LOGOUT_TO_FULL_TIMEOUT);

            dispatch({
                type: 'AUTH_SET_FIELD',
                payload: {
                    cookieTimeout: {
                        id: fullLogoutTimer,
                        clear: () => {
                            clearTimeout(fullLogoutTimer);
                            done();
                        }
                    }
                }
            });
        }, deps.action.payload.delay);

        dispatch({
            type: 'AUTH_SET_FIELD',
            payload: {
                cookieTimeout: {
                    id: timeout,
                    clear: () => {
                        clearTimeout(timeout);
                        done();
                    }
                }
            }
        })
    }
});
