// @flow
import { LoginBase } from './Login';
import { ManageBase } from './Manage';
import { PreLoginCheckBase } from './PreLoginCheckBase';
import { Route, Router, Switch } from 'react-router';
import { history } from '../../lib/history';
import { ForgottenPassword, ResetPassword } from './ResetPassword';
import { useSegment } from '../../segment';
import { useLoginCheck, UseLoginCheckHook } from '../hoc/Login';
import { EmailVerification } from './EmailVerification';
import { SignUp } from './SignUp';
import { Logout } from './Logout';
import { useCheckBeta } from '../../beta';
import { usePlausible } from '../../plausible';
import { useControlPanelHtmlTitles } from '../hoc/lib';

import type { AuthStatus } from '../../state/auth/type';

const statusMap: Map<AuthStatus, React$StatelessFunctionalComponent<{}>> = new Map([
    ['unchecked', PreLoginCheckBase],
    ['invalid', LoginBase],
    ['valid', ManageBase]
]);

function ManageApp({ loginCheck: { status }}: { loginCheck: UseLoginCheckHook }) {
    const Component = statusMap.get(status);

    return (
        Component
            ? <div className="App"><Component/></div>
            : <div>Unknown auth status</div>
    );
}


function MaybeAuthedRoutes() {
    /**
     * These routes might need auth, and the gui chooses where to redirect
     * the user by default when rendering them; so it shows a PreLoginCheckBase
     * while making the API requests before deciding where to redirect.
     */

    const loginCheck = useLoginCheck();

    const { showMaybeAuthedGui } = loginCheck;

    // prevent any flash of gui before redirects
    if (!showMaybeAuthedGui) {
        return <PreLoginCheckBase />
    }

    return (
        <Switch>
            {/* some signup subroutes need auth, they get it via AuthedSignup */}
            <Route path="/signup" component={SignUp} />

            {/* full auth needed for ManageApp, and it shows a login page if necessary */}
            <Route render={() => <ManageApp loginCheck={loginCheck} /> } />
        </Switch>
    )
}

function ControlPanel() {
    /**
     * Global hooks that could affect anywhere in the gui
     */
    useSegment();
    usePlausible();
    useCheckBeta();
    useControlPanelHtmlTitles();

    /**
     * Render the routes here that don't need API auth.
     *
     * Rendering them here means they appear 'immediately', without any
     * decision being made in the gui about what page to redirect to.
     */
    return (
        <Switch>
            {/* this route just DELETEs any auth and redirects to "/" */}
            <Route path='/logout/' component={Logout}/>

            {/* these routes don't need auth, and if the browser is authed, it's ignored anyway */}
            <Route path="/reset_password" component={ResetPassword}/>
            <Route path="/forgotten_password" component={ForgottenPassword}/>
            <Route path='/eva/:uid' component={EmailVerification}/>

            <Route component={MaybeAuthedRoutes} />
        </Switch>
    );
}

const TopRouter = () => (
    <Router history={history}>
        <ControlPanel/>
    </Router>
);

export const App: React$StatelessFunctionalComponent<{}> = () => <TopRouter />;
