// @flow

import { useMutation, useQuery } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import { kioskClient } from '../api/graphql';
import { parseIsoDate } from '../lib/date';
import gql from 'graphql-tag';

export const GET_USER_DETAILS = "GetUserDetails";

const KIOSK_USER_QUERY = gql`
query ${GET_USER_DETAILS} {
    isEmailVerificationRequired
    user {
        id
        email
        isEmailVerified
        isTwoFactorEnabled
        emailChangeRequest {
            currentEmail
            pendingEmail
        }
        emailVerificationAttempt {
            createdAt
            expiresAt
        }
    }
}`;

const KIOSK_REQUEST_EMAIL_VERIFICATION_MUTATION = gql`
mutation {
    requestEmailVerification {
        user {
            id
            email
            isEmailVerified
            isTwoFactorEnabled
            emailVerificationAttempt {
                createdAt
                expiresAt
            }
        }
    }
}`;

// How many minutes to hide and disable the "Resend Verification Email"
// button for to prevent the user repeatedly requesting emails, even
// though the server silently ignores them for 5 minutes.
const REPEAT_VERIFICATION_REQUEST_TIMEOUT = 5;

export const useEmailVerification = () => {
    const [emailVerificationCreated, setEmailVerificationCreated] = useState(null);
    const [emailVerificationExpiry, setEmailVerificationExpiry] = useState(null);
    const [isEmailVerificationExpired, setIsEmailVerificationExpired] = useState(false);
    const [isEmailVerificationRequestable, setIsEmailVerificationRequestable] = useState(false);
    const [isEmailVerificationRequired, setIsEmailVerificationRequired] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [pendingEmail, setPendingEmail] = useState(null);

    const { data: userData, loading: isQueryLoading, error: queryError } = useQuery(
        KIOSK_USER_QUERY,
        { client: kioskClient }
    );

    const [
        requestEmailVerification,
        { data: verificationData, loading: isVerificationLoading, error: verificationError },
    ] = useMutation(
        KIOSK_REQUEST_EMAIL_VERIFICATION_MUTATION,
        { client: kioskClient }
    );

    function handleDataUpdate(data) {
        if (!data) {
            return;
        }

        console.log('data', data);
        const currentTime = new Date();

        if (data?.isEmailVerificationRequired !== undefined) {
            setIsEmailVerificationRequired(data?.isEmailVerificationRequired);
        }

        if (data && data?.user) {
            const { user } = data;
            if (user?.isEmailVerified !== undefined) {
                setIsEmailVerified(user.isEmailVerified);

                if (user?.isEmailVerified) {
                    setEmailVerificationCreated(null);
                    setEmailVerificationExpiry(null);
                    setIsEmailVerificationExpired(false);
                    setIsEmailVerificationRequestable(false);
                } else {
                    if (user?.emailVerificationAttempt) {
                        const { emailVerificationAttempt } = user;
                        if (emailVerificationAttempt.createdAt) {
                            const emailVerificationCreated = parseIsoDate(emailVerificationAttempt.createdAt);
                            const timeDifference = emailVerificationCreated ? (currentTime - emailVerificationCreated) / 1000 / 60 : 0;

                            setEmailVerificationCreated(emailVerificationCreated);
                            setIsEmailVerificationRequestable(timeDifference > REPEAT_VERIFICATION_REQUEST_TIMEOUT);
                        }
                        if (emailVerificationAttempt.expiresAt) {
                            setEmailVerificationExpiry(parseIsoDate(emailVerificationAttempt.expiresAt));
                            setIsEmailVerificationExpired(emailVerificationAttempt.createdAt < currentTime);
                        }
                    } else {
                        setEmailVerificationCreated(null);
                        setEmailVerificationExpiry(null);
                        setIsEmailVerificationExpired(false);
                        setIsEmailVerificationRequestable(true);
                    }
                }
            } else {
                console.error('User email verification status is undefined');
            }

            if (user?.emailChangeRequest) {
                const { emailChangeRequest } = user;
                if (emailChangeRequest.pendingEmail) {
                    console.log('Pending email change:', emailChangeRequest.pendingEmail);
                    setPendingEmail(emailChangeRequest.pendingEmail);
                }
            }
        }
    }

    useEffect(() => {
        handleDataUpdate(userData);
    }, [userData]);

    useEffect(() => {
        handleDataUpdate(verificationData?.requestEmailVerification);
    }, [verificationData]);

    useEffect(() => {
        if (queryError) {
            console.error('Query Error:', queryError);
        }
    }, [queryError]);

    useEffect(() => {
        if (verificationError) {
            console.error('Verification Error:', verificationError);
        }
    }, [verificationError]);

    const isUserEmailVerified = userData?.user?.isEmailVerified;
    const isUserTwoFactorEnabled = userData?.user?.isTwoFactorEnabled || false;

    return {
        emailVerificationCreated,
        emailVerificationExpiry,
        isEmailVerificationExpired,
        isEmailVerificationRequestable,
        isEmailVerificationRequired,
        isEmailVerified,
        isQueryLoading,
        isUserEmailVerified,
        isUserTwoFactorEnabled,
        isVerificationLoading,
        pendingEmail,
        queryError,
        requestEmailVerification,
        userData,
        verificationData,
        verificationError,
    };
};