// @flow

import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { TextInput, } from '../../../element/Input';
import { Dialog, useDialog } from "../../../element/Dialog";
import { Notice } from "../../../element/Notice";
import { SkeletonBar } from "../../../element/Skeleton";

import type { BbUser } from '../../../../api/type';
import type { EditorModal } from '../../../common/Editor';

type UserEmailEditorProps = {
    +editor: EditorModal<string, ?string, BbUser>,
    +email_address: ?string;
    +pendingEmail: ?string;
}

export function UserEmailEditor({ editor, email_address, pendingEmail }: UserEmailEditorProps) {
    const { value, setValue } = editor;

    const confirmDialog = useDialog([
        {
            label: 'Send Verification Email',
            kind: 'primary',
            onSelect: () => editor.onSave()
        }
    ]);

    return (
        <>
            <Dialog
                title='Change Email Address?'
                dialog={confirmDialog}
                render={() => (
                    <>
                        <p>
                            Are you sure you want to change your email address from <b className='whitespace-no-wrap'>{email_address}</b> to <b className='whitespace-no-wrap'>{value}</b>
                        </p>
                        <Notice type='info' icon='info-fill'>
                            <p>We'll send a verification email to your new address - to confirm the
                            change please click the link contained in that email.</p>
                        </Notice>
                    </>
                )}
            />

            <Panel>
                <PanelHeader
                    title='Change Email Address'
                    description='Provide your new email address to use with Brightbox'
                />
                <PanelBar>
                    <div className='max-w-lg'>
                        {pendingEmail
                            ? <Notice type='warning' icon='info-fill' className='mb-4'>
                                <p>You already have a pending request to change your email address
                                to <b>{pendingEmail}</b> - please check your email for the verification link
                                to confirm the change.</p>
                                <p>Alternatively, you can start another change request below.</p>
                            </Notice>
                            : null
                        }

                        {editor.status === 'edit'
                            ? <TextInput
                                label='Email Address'
                                value={value || ''}
                                onChange={(value) => setValue(value)}
                                focusSelectAll={true}
                                autoFocus={true}
                                errorText={editor.errors}
                            />
                            : <SkeletonBar size='xl' />
                        }
                    </div>
                </PanelBar>
                <PanelButtonBar
                    cacheStatus={editor.messages?.status}
                    primaryButton={{
                        onClick: editor.status === 'edit' ? confirmDialog.show : null,
                        disabled: email_address === value,
                    }}
                    cancel={editor.status === 'edit' ? editor.onCancel : null}
                />
            </Panel>
        </>
    );
}