// @flow
import { useCallback, useEffect } from 'react';
// shallowEqual IS exported.
// $FlowFixMe missing-export
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useMessages } from './Messages';
import { historyNavigateOnCreate, historyNavigateOnDelete } from '../../lib/history';
import { RC_INITIAL } from '../../state/resource/type';
import { useCurrentAccount, useCurrentAccountId } from './lib';

import type { BbAllResourceTypes, BbResourceKind, } from '../../api/type';
import type { CloudGuiState } from '../../state/cloudgui';
import type { Dispatch } from 'redux';
import type {
    ResourceAction,
    ResourceActionMethod,
    ResourceCacheStatus,
    ResourceCollection,
} from '../../state/resource/type';
import type { CloudIpAction } from '../../state/CloudIp/type';
import type { MessageRemoveAction } from '../../state/Message/type';
import type { MessageHook } from './Messages';
import type { BbFirewallRule, BbFirewallRuleParams } from '../../api/type.fwp';

export type ViewResourceProps<Resource, Params> = {
    id: string,
    item: ?Resource,
    status: ResourceCacheStatus,
    messagesId: string,
    apiResult: MessageHook<Resource>,
    simpleAction: (action: string, params?: ?$Shape<Params>, nonPristine: ?Resource, method?: ?ResourceActionMethod) => void,
    deleteAction: () => void,
    patchResource: (params: $Shape<Params>, nonPristine: ?Resource) => void;
};

type ViewResourceDispatch<Resource> = Dispatch<ResourceAction<Resource, any> | CloudIpAction | MessageRemoveAction>;

type Hpps<Resource> = {
    item: ?Resource,
    status: ResourceCacheStatus,
}

const selectHpps = <Resource, Params>(resources: ?ResourceCollection<Resource, Params>, id: ?string): Hpps<Resource> => ({
    item: id && resources ? resources.full[id] : null,
    status: id && resources ? resources.cacheStatus.get(id) || RC_INITIAL : RC_INITIAL,
});

export type ResourceFetchHook<Resource> = {
    item: ?Resource,
    status: ResourceCacheStatus,
    fetch: () => void,
}

export function useResourceFetch<Resource, Params>(kind: BbResourceKind, id: ?string): ResourceFetchHook<Resource> {
    const accountId = useCurrentAccountId() || '';
    const dispatch = useDispatch<ViewResourceDispatch<Resource>>();
    const { status, item } = useSelector((state: CloudGuiState) => selectHpps(((state.Resource[kind]: any): ?ResourceCollection<Resource, Params>), id), shallowEqual);

    const fetch = useCallback(() => {
        if ((accountId || kind === 'user') && status === RC_INITIAL && kind != null && id != null) {
            dispatch({ type: 'RESOURCE_FETCH_FULL', payload: { kind, id } });
        }
    }, [accountId, id, dispatch, kind, status]);

    return {
        item,
        status,
        fetch,
    }
}

export const useViewResource = <Resource: BbAllResourceTypes, Params: Object>(
    kind: BbResourceKind, id: string
): ViewResourceProps<Resource, Params> => {
    const messagesId = `${kind}_${id}`;
    const accountId = useCurrentAccountId() || '';
    const messages = useMessages<Resource>(messagesId);
    const { item, status, fetch } = useResourceFetch(kind, id);

    const dispatch = useDispatch<ViewResourceDispatch<Resource>>();
    useEffect(() => {
        if (fetch) fetch();
    }, [fetch]);

    useEffect(() => {
        return () => {
            dispatch({ type: 'MESSAGE_REMOVE', payload: { id: messagesId } });
        }
    // we only want this to run on dismount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {

        messagesId,
        apiResult: messages,
        simpleAction: (simpleAction: string, params?: ?$Shape<Params>, nonPristine: ?Resource, method?: ?ResourceActionMethod) => {
            dispatch({
                type: 'RESOURCE_SIMPLE_ACTION',
                payload: {
                    kind, id, action: simpleAction, params, nonPristine, messagesId, method
                }
            });
        },
        deleteAction: () => {
            dispatch({ type: 'RESOURCE_DELETE_ACTION', payload: { kind, id, } });
            historyNavigateOnDelete(accountId, kind);
        },
        patchResource: (params: $Shape<Params>, nonPristine: ?Resource) => {
            dispatch({ type: 'RESOURCE_PATCH', payload: { kind, id, params, nonPristine, messagesId } });
        },
        id,
        item,
        status,
    };
};

/**
 * We need to navigate differently when deleting a FWR - back to the server group
 * that contains the firewall policy that contains the rule...
 *
 * So this just changes the redirect.
 */
export const useViewFirewallRule = (id: string, server_group_id: string): ViewResourceProps<BbFirewallRule, BbFirewallRuleParams> => {
    const dispatch = useDispatch<ViewResourceDispatch<BbFirewallRule>>();
    const account = useCurrentAccount();

    return {
        ...useViewResource('firewall_rule', id),
        deleteAction: () => {
            if (account) {
                dispatch({ type: 'RESOURCE_DELETE_ACTION', payload: { kind: 'firewall_rule', id, } });
                // use the 'on create' nav for the group - it gets us where we want to go...
                historyNavigateOnCreate(account, server_group_id, 'server_group');
            }
        },
    };
}