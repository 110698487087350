// @flow
/* global geoip2 */
/* global analytics */
import { useEffect, useState } from 'react';
import { emptyErrors } from '../common/lib';
import to from 'await-to-js';
import axios from 'axios';
import { accountsUrl, authUrl, usersUrl } from '../../api/url';
import { login, request } from '../../api/rest';
import { history } from '../../lib/history';
import { checkEventConnection, setAccountId } from '../../api/events';
import { store } from '../../state/cloudgui';
import { RC_API_REQUEST, RC_CACHED, RC_ERROR, RC_INITIAL, RC_SUCCESS } from '../../state/resource/type';
import { setLoginStateFromScopes } from '../../state/auth/AuthLogic';
import { useDispatch, useSelector } from 'react-redux';
import { resourceMetas } from '../../api/adapter';
import { waitForAccountEvent, waitForEventsConnected } from './SignUp.lib';
import { trackEvent } from '../../plausible';
import { useUrlParamValue } from "./lib";

import type { FormErrors } from '../common/lib';
import type { BbAccountParams, } from '../../api/type.acc';
import type { StateUpdateFn } from 'react-hooks';
import type { ResourceCacheStatus } from '../../state/resource/type';
import type { Dispatch } from 'redux';
import type { AuthAction } from '../../state/auth/type';
import type { CloudGuiState } from '../../state/cloudgui';
import type { CardSaveStatus } from './Billing';

type UserDetails = {
    name: string,
    email_address: string,
    password0: string,
    terms: boolean,
}

type UserSignUpHook = {
    status: ResourceCacheStatus,
    errors: FormErrors,
    user: UserDetails,
    setUser: StateUpdateFn<UserDetails>,
    onSignUp: () => Promise<void>,
    emailWasPrefilled: boolean;
}

type AccountSignUpHook = {
    status: ResourceCacheStatus,
    errors: FormErrors,
    geocodeStatus: ResourceCacheStatus,
    account: BbAccountParams,
    setAccount: StateUpdateFn<BbAccountParams>,
    onCreateAccount: () => Promise<void>,
}

// arguably should live in SignUp.lib.js, but moving it there
// somehow breaks the regex in jest.
// totaly mystery to me, but no harm leave it here really.
export const EMAIL_REGEX = /[^@]@[^@]+\.[^@]+/;

export function useUserSignUp(): UserSignUpHook {
    const dispatch = useDispatch<Dispatch<AuthAction>>();
    const [errors, setErrors] = useState<FormErrors>(emptyErrors);
    const [status, setStatus] = useState<ResourceCacheStatus>(RC_INITIAL);

    const prefillEmail = useUrlParamValue('email=([^&]+)');
    const emailWasPrefilled = !!prefillEmail;

    const [user, setUser] = useState<UserDetails>({
        name: '',
        email_address: prefillEmail || '',
        password0: '',
        terms: false,
    });

    const onSignUp = async () => {
        setStatus(RC_INITIAL);
        const nextErrors = new Map<string, string>();
        if (user.name.length < 3) nextErrors.set('user_name', 'Please enter your name');
        if (!user.email_address.match(EMAIL_REGEX)) nextErrors.set('user_email_address', 'Please enter your email address');
        if (user.password0.length < 8) nextErrors.set('user_password0', 'Please enter a password of at least 8 characters');
        if (!user.terms) nextErrors.set('user_terms', 'Please check the Terms & Conditions');

        setErrors(nextErrors);
        if (nextErrors.size === 0) {
            setStatus(RC_API_REQUEST);

            const [userErr, userResponse] = await to(axios({
                url: usersUrl,
                method: 'POST',
                data: {
                    name: user.name,
                    email_address: user.email_address,
                    password: user.password0,
                    terms_agreed: "1",
                },
            }));

            if (userErr) {
                setStatus(RC_ERROR);
                if (userErr.response.data.errors?.[0] === 'Email address has already been taken') {
                    dispatch({
                        type: 'AUTH_SET_FIELD',
                        payload: {
                            username: user.email_address,
                        }
                    });
                    setErrors(new Map([
                        ['user_exists', 'user_exists'],
                    ]));
                } else {
                    setErrors(new Map([
                        ['submit', userErr.response.data.errors.join('. ')],
                    ]));
                }
                return;
            }

            analytics.alias(userResponse.data.id);
            analytics.track('Created User');

            // Plausible Analytics
            trackEvent('User Created');

            dispatch({ type: 'AUTH_SET_FIELD', payload: { currUser: resourceMetas['user'].adapt.collected(userResponse.data), } });

            // now login
            const formData = new FormData();
            formData.set('username', user.email_address);
            formData.set('password', user.password0);

            const loginRequest = {
                method: 'POST',
                url: authUrl,
                data: formData,
            };

            const [loginErr,loginResponse] = await to(login(loginRequest));
            if (loginErr) {
                setStatus(RC_ERROR);
                setErrors(new Map([
                    ['submit', loginErr.response.data.errors.join('. ')],
                ]));
                return;
            }

            // set redux up so it knows we are logged in
            setLoginStateFromScopes(dispatch, loginResponse.headers['x-oauth-scopes'])
            // connect faye - we use account activated events when creating an account
            checkEventConnection();

            // we know there are no accounts, so force this state
            dispatch({
                type: 'RESOURCE_SET_COLLECTED',
                payload: {
                    kind: 'account',
                    resources: [],
                }
            });
            dispatch({ type: 'RESOURCE_COLLECTED_STATUS', payload: { kind: 'account', fetched: RC_CACHED } });
            dispatch({
                type: 'AUTH_FIRST_SWITCH_COMPLETE',
            });
            // these actions ^ are enough to get useLoginCheck to "showMaybeAuthedGui", which now prevents
            // a flash of PreLoginCheckBase before the collabs are checked in SwitchAccountLogic.

            // trigger the appropriate redirect through SwitchAccountLogic
            dispatch({ type: 'AUTH_USER_SWITCH_ACCOUNT', payload: { account: null }});
        }
    };


    return {
        errors, status,
        user, setUser,
        emailWasPrefilled,
        onSignUp,
    };
}

async function setAccountName(setAccount: (BbAccountParams | (BbAccountParams => BbAccountParams)) => void) {
    let [, res] = await to(request({
        method: 'GET',
        url: usersUrl,
        accountId: false,
    }));

    if (res && res.data.length) {
        setAccount((acc: BbAccountParams) => ({...acc, name: res.data[0].name + "'s Account"}));
    }
}

export function useAccountSignUp(accountIdFromUrl: ?string, submitCardUpdate: (accountId: string, event: ?SyntheticEvent<any>) => Promise<CardSaveStatus>): AccountSignUpHook {
    const dispatch = useDispatch<Dispatch<AuthAction>>();
    const name = useSelector((state: CloudGuiState) => state.Auth.currUser
        ? state.Auth.currUser.name + "'s Account"
        :  ''
    );
    const [errors, setErrors] = useState<FormErrors>(emptyErrors);
    const [status, setStatus] = useState<ResourceCacheStatus>(RC_INITIAL);
    const [geocodeStatus, setGeocodeStatus] = useState<ResourceCacheStatus>(RC_INITIAL);

    const [account, setAccount] = useState<BbAccountParams>({
        name,
        address_1: '',
        address_2: '',
        city: '',
        county: '',
        postcode: '',
        country_name: '',
        country_code: '',
        telephone_number: '',
        vat_registration_number: '',
    });

    function redactUrl(url: string) {
        if (typeof url === 'string') {
            return url.replace(/\/acc-.+/g, "/acc-xxxxx")
        }
    }

    useEffect(() => {
        if (typeof geoip2 !== 'undefined') {
            geoip2.country(
                (result) => {
                    setAccount((x) => ({ ...x, country_code: result.country.iso_code }))
                    setGeocodeStatus(RC_SUCCESS);
                },
                () => {
                    setAccount((x) => ({ ...x, country_code: 'GB' }))
                    setGeocodeStatus(RC_ERROR);
                }
            );
        } else {
            setAccount((x) => ({ ...x, country_code: 'GB' }))
            setGeocodeStatus(RC_ERROR);
        }
    }, [setGeocodeStatus, setAccount]);

    useEffect(() => {
        setAccountName(setAccount);
    }, []);


    const onCreateAccount = async () => {
        setStatus(RC_INITIAL);
        const nextErrors = new Map<string, string>();

        if (account.name === '') nextErrors.set('account_name', 'Please enter an account name.');
        if (account.country_code === '') nextErrors.set('account_country_code', 'Please select your country.');

        setErrors(nextErrors);
        let accountId = accountIdFromUrl || '';

        if (nextErrors.size === 0) {

            setStatus(RC_API_REQUEST);
            if (!accountId.match(/^acc-[a-z0-9]{5}/)) {

                const [accountErr, accountRes] = await to(request({
                    url: accountsUrl,
                    method: 'POST',
                    data: {
                        name: account.name,
                        country_code: account.country_code,
                    }
                }));

                if (accountErr) {
                    setStatus(RC_INITIAL);
                    setErrors(new Map([
                        ['submit', accountErr.response.data.errors.join(', ')],
                    ]));
                    return;
                }

                history.replace('/signup/' + accountRes.data.id + window.location.search);

                analytics.track('Created Account');

                // Plausible Analytics
                trackEvent('Account Created',{},{url: redactUrl(window.location.href)});

                // make sure the scopes are set, or else faye won't connect
                setLoginStateFromScopes(dispatch, accountRes.headers['x-oauth-scopes'])
                setAccountId(accountRes.data.id);

                accountId = accountRes.data.id;

                const adapted = resourceMetas.account.adapt.full(accountRes.data);

                // this is all pre-faye connection, so make sure redux has this one cached
                // before we redirect to the main gui.
                dispatch({
                    type: 'RESOURCE_ADD_FULL',
                    payload: {
                        kind: 'account',
                        full: adapted,
                        collected: adapted,
                    }
                });


            } else {
                const [accountErr, ] = await to(request({
                    url: accountsUrl + '/' + accountId,
                    method: 'PUT',
                    data: {
                        name: account.name,
                        country_code: account.country_code,
                    }
                }));

                if (accountErr) {
                    setStatus(RC_INITIAL);
                    setErrors(new Map([
                        ['submit', accountErr.response.data.errors.join(', ')],
                    ]));
                    return;
                }
            }

            let connected = await waitForEventsConnected(store);

            if (accountId) {
                let cardSaved = await submitCardUpdate(accountId);

                if (cardSaved === RC_ERROR) {
                    // useUpdateBillingMethod exposes the error, so we'll use it from there.
                    setStatus(RC_INITIAL);
                    return;
                }

                if (connected) {
                    let activated = await waitForAccountEvent(accountId, store);

                    if (!activated) {
                        // just push to the dashboard and let the banner show up.
                        history.push(`/accounts/${accountId}/?pending`);
                        return;
                    }
                }

                analytics.track('Completed Account Signup');

                // Plausible Analytics
                trackEvent('Account Activated',{},{url: redactUrl(window.location.href)});

                setStatus(RC_SUCCESS);

                // and trigger a regular 'switch' to the new account
                dispatch({
                    type: 'AUTH_USER_SWITCH_ACCOUNT',
                    payload: {
                        accountId,
                    },
                });
                history.push(`/accounts/${accountId}/`);
            }
        }
    };

    return {
        errors, status,
        account, setAccount,
        geocodeStatus,
        onCreateAccount,
    };
}