// @flow
import { Textarea } from './Textarea';
import { Button } from './Button';
import copy from 'copy-to-clipboard';
import { TextInput } from './Input';

import type { SecretValue } from '../../state/Secret/type';

type SecretDisplayProps = {
    label?: ?string,
    secret: ?SecretValue,
}

export function SecretDisplay({ secret, label, }: SecretDisplayProps): React$Node {

    if (Array.isArray(secret)) {
        return (
            <>
                <Textarea
                    label={label}
                    value={secret.join('\n')}
                    className="w-full mb-1"
                    focusSelectAll={true}
                    readOnly={true}
                    onChange={null}
                    rows={5}
                />
                <Button className='mb-1' size="sm" kind='bare' preIcon={'copy'} onClick={() => copy(secret.join('\n'))}>Copy to Clipboard</Button>
            </>
        );
    }

    if (typeof secret === 'string') {
        return (
            <TextInput
                label={label}
                value={secret}
                readOnly={true}
                onChange={() => void 0}
                autoPopulated={true}
                focusSelectAll={true}
                postText='Copy'
                onPostInlayClick={() => copy(secret)}
                className='w-full mb-5 mr-3'
            />
        )
    }


    return null;
}