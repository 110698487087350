// @flow

import { useLoginCheck } from "../hoc/Login";
import { LoginForm } from "./Login";

// Wrap the account create or collab accept components in this, and it will ensure that the user
// is logged in to honcho before showing them.
// auth is required for collab accept / account create, so this centralises that for the signup pages.
export function AuthedSignup({ render }) {
    const { status } = useLoginCheck();

    switch(status) {
        case "unchecked":
            // this should never be displayed; MaybeAuthedRoutes contains SignUp which contains this,
            // and MaybeAuthedRoutes should wait for "firstAccountSwitchComplete" before rendering any AuthedSignup.
            return <div>Loading...</div>
        case "invalid":
            return <LoginForm showCreateAccount={false} />
        case "valid":
            return render();
        default:
            return (
                <div>Unknown status: {status}</div>
            );
    }
}
