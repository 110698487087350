// @flow
import { TextInput } from '../../../element/Input';
import { Notice } from '../../../element/Notice';
import { useEnableTwoFactorAuth } from '../../../hoc/User';
import { ENTER } from "../../../hoc/GlobalSearch";
import { Dialog } from "../../../element/Dialog";

import type { TwoFactorHook } from '../../../hoc/User';
import type { BbUser, } from '../../../../api/type';

export type TwoFactorProps = {
    +twoFactor: TwoFactorHook;
    +user: BbUser,
    +userLoading: boolean,
};

export function TwoFactorEnableDialog({ twoFactor, user, userLoading }: TwoFactorProps) {
    return (
        <Dialog
            title='Two-Factor Authentication'
            dialog={twoFactor.enableDialog}
            messages={twoFactor.messages}
            render={() => (
                <EnableTwoFactorAuth twoFactor={twoFactor} user={user} userLoading={userLoading}/>
            )}
        />);
}

export function EnableTwoFactorAuth({ twoFactor: { code, setCode, errors, enableDialog }, user, }: TwoFactorProps): React$Node {
    const qrImgData = useEnableTwoFactorAuth(user.id || '');

    return (
        <>
            <p>
                Scan the QR code with your OTP application and enter
                the authentication code it generates.
            </p>

            <img className='w-56 h-56 bg-white' src={qrImgData} alt='Loading...'/>

            <TextInput
                autoFocus={true}
                value={code || ''}
                label='Authentication Code'
                className='w-48 ml-4'
                onChange={(value: string) => setCode(value)}
                onKeyDown={(e) => {
                    if (e.keyCode === ENTER) {
                        enableDialog.actions[0].onSelect();
                        e.preventDefault();
                    }
                }}
            />
            {errors.errors
                ? <Notice type='error' className="mt-4">{errors.errors}</Notice>
                : null
            }
        </>
    );
}
