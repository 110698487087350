// @flow
import type { BbCollaboration, BbUser } from '../api/type';

// Check if the user is able to accept a collaboration invitation due to
// Two-Factor Authentication or other requirements.
export function isAbleToAcceptCollaboration(
  currentUser: BbUser,
  collab: BbCollaboration
): boolean {
  return (
      currentUser?.email_verified
      && (!collab?.tfa_required || currentUser?.['2fa'].enabled)
  )
}
