// @flow
import { useDialog } from '../element/Dialog';
import gql from 'graphql-tag';
import { useEditorErrors } from '../common/Editor';
import { useCallback, useEffect, useState } from 'react';
import { useTriggeredMessages } from './Messages';
import { useResourceFetchMutation } from './graphql';
import { kioskClient } from '../../api/graphql';
import { useDispatch } from 'react-redux';
import { RC_API_REQUEST, RC_ERROR, RC_INITIAL, RC_SUCCESS } from '../../state/resource/type';
import to from 'await-to-js';
import QRCode from 'qrcode';
import { BbUserPatchParams } from "../../api/type";
import { GET_USER_DETAILS } from "../../hooks/useEmailVerification";
import { useViewResource } from "./ViewResource";

import type { DialogState } from '../element/Dialog';
import type { EditorErrors } from '../common/Editor';
import type { BbUser } from "../../api/type";
import type { ViewResourceProps } from "./ViewResource";
import type { Dispatch } from 'redux';
import type { SecretAction } from '../../state/Secret/type';
import type { MessageStateAction } from '../../state/Message/type';
import type { StateUpdateFn } from "react-hooks";
import type { MessageHook } from "./Messages";

export type TwoFactorHook = {
    +disableDialog: DialogState<null>;
    +enableDialog: DialogState<null>;

    +code: ?string;
    +setCode: StateUpdateFn<?string>;
    +messages: MessageHook<null>;
    +errors: EditorErrors<string>;
}

const DISABLE_2FA_MUTATION = gql`
mutation {
    disableTwoFactorEnrolment {
        user {
          isTwoFactorEnabled
        }
    }
}`;

const START_2FA_MUTATION = gql`
mutation {
    beginTwoFactorEnrolment {
        user {
          isTwoFactorEnabled
        }
        provisioningSecret
        provisioningUri
    }
}`;

const CONFIRM_2FA_MUTATION = gql`
mutation confirm ($totp: String!) {
    completeTwoFactorEnrolment(input: { totp: $totp }) {
        backupCodes
    }
}`;


export function useTwoFactorEditor(userId: ?string): TwoFactorHook {
    const errors = useEditorErrors<string>('');
    const [code, setCode] = useState<?string>(null);
    const status = code === null ? false : 'edit';
    const { setErrors, clearErrors } = errors;
    const { next: nextMessages, messages, } = useTriggeredMessages();

    const [confirm2fa,] = useResourceFetchMutation(
        'user', userId || '', CONFIRM_2FA_MUTATION, {
            client: kioskClient,
            refetchQueries: ['GetUserDetails'],
        }
    );

    const [disable2fa,] = useResourceFetchMutation(
        'user', userId || '', DISABLE_2FA_MUTATION, {
            client: kioskClient,
            refetchQueries: ['GetUserDetails'],
        }
    );

    const dispatch = useDispatch<Dispatch<SecretAction | MessageStateAction>>();

    useEffect(() => {
        if (!status) {
            clearErrors();
            setCode('');
        }
    }, [status, clearErrors, setCode, userId]);

    const disableDialog = useDialog([
        {
            label: 'Disable Two-Factor',
            kind: 'primary',
            color: 'red',
            onSelect: async () => {
                const id = nextMessages();
                dispatch({ type: 'MESSAGE_MESSAGE', payload: { id, status: RC_API_REQUEST, } });
                let [err,] = await to(disable2fa());
                if (err && err.message) {
                    dispatch({ type: 'MESSAGE_MESSAGE', payload: { id, status: RC_ERROR, } });
                    setErrors(err.message);
                } else {
                    dispatch({ type: 'MESSAGE_MESSAGE', payload: { id, status: RC_SUCCESS, } });
                    setCode(null);
                    // clear out the messages in case the user immediately shows the enable 2fa dialog
                    nextMessages();
                }
            }
        }
    ]);

    const enableDialog = useDialog([{
        label: 'Enable Two-Factor',
        kind: 'primary',
        onSelect: async () => {
            const saveUserId = userId, saveValue = code || '';

            if (saveUserId && saveValue != null) {
                setErrors('');

                if (!saveValue.match(/^[0-9]{6}$/)) {
                    setErrors('Please enter the code');
                    return false;
                }

                const id = nextMessages();
                dispatch({ type: 'MESSAGE_MESSAGE', payload: { id, status: RC_API_REQUEST, } });

                let tmp = await to(confirm2fa({ variables: { totp: saveValue } }));
                let [err, res] = tmp;
                if (err && err.message) {
                    setErrors(err.message);
                    // RC_ERROR here would hide the dialog. this situation effectively *is* RC_INITIAL,
                    // since no changes have been made in honcho
                    dispatch({ type: 'MESSAGE_MESSAGE', payload: { id, status: RC_INITIAL, } });
                    return false;
                } else if (
                    res
                    && Array.isArray(res.data.completeTwoFactorEnrolment?.backupCodes)
                    && res.data.completeTwoFactorEnrolment.backupCodes.length > 0
                ) {
                    dispatch({
                        type: 'SECRET_SET',
                        payload: {
                            id: saveUserId,
                            value: res.data.completeTwoFactorEnrolment.backupCodes,
                        }
                    });

                    dispatch({ type: 'MESSAGE_MESSAGE', payload: { id, status: RC_SUCCESS, } });
                    setCode('');

                    // clear out the messages in case the user immediately shows the disable 2fa dialog
                    nextMessages();
                } else {
                    setErrors('Please check and re-enter your code');
                    // RC_ERROR here would hide the dialog. this situation effectively *is* RC_INITIAL,
                    // since no changes have been made in honcho
                    dispatch({ type: 'MESSAGE_MESSAGE', payload: { id, status: RC_INITIAL, } });
                    return false;
                }
            }
        }
    }])

    return {
        disableDialog,
        enableDialog,
        code, setCode,
        errors,
        messages,
    };
}

export function useEnableTwoFactorAuth(userId: string): string {
    const [qrImgData, setQrImgData] = useState<string>('');
    const [start2fa, startStatus] = useResourceFetchMutation(
        'user', userId, START_2FA_MUTATION, { client: kioskClient }
    );
    const qrRawData = startStatus.data ? startStatus.data.beginTwoFactorEnrolment.provisioningUri : '';
    useEffect(() => {
        if (qrRawData !== '') {
            QRCode.toDataURL(qrRawData, { width: 224 }, function (err, url) {
                setQrImgData(url);
            });
        }
    }, [qrRawData]);

    useEffect(() => {
        start2fa();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return qrImgData;
}

type ViewUserProps = ViewResourceProps<BbUser, BbUserPatchParams> & {
    emailMessages: MessageHook<string>;
}

/**
 * We need to trigger a refetch on user gql query when the email address is updated,
 * so intercept the patchResource call and trigger the apollo refetch as needed.
 */
export function useViewUser(id: string): ViewUserProps {
    const { patchResource: patch, ...view } = useViewResource('user', id);
    const { next, messages: emailMessages } = useTriggeredMessages();
    const dispatch = useDispatch();

    const patchResource = useCallback((params: BbUserPatchParams, nonPristine: ?BbUser) => {
        if ('email_address' in params) {
            const messagesId = next();
            dispatch({
                type: 'RESOURCE_PATCH', payload: {
                    kind: 'user',
                    messagesId,
                    id, params, nonPristine,
                }
            });
        } else {
            patch(params);
        }
    }, [patch, id, next, dispatch]);

    const { status } = emailMessages;

    useEffect(() => {
        if (status === RC_SUCCESS) {
            kioskClient.refetchQueries({
                include: [GET_USER_DETAILS],
            });
        }
    }, [status]);

    return {
        patchResource,
        emailMessages,
        ...view,
    }
}