// @flow

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import { Layout } from './Layout';
import gql from 'graphql-tag';
import { Button } from "../element/Button";
import { Notice } from "../element/Notice";
import { PreLoginCheckBase } from '../base/PreLoginCheckBase';
import { apiClient } from "../../api/graphql";


const COMPLETE_EMAIL_VERIFICATION_MUTATION = gql`
mutation CompleteEmailVerification($uid: String!) {
  completeEmailVerification(input: { uid: $uid }) {
    errors
    success
    result
  }
}`;

const messages = {
    VERIFIED: 'Your email address is already verified',
    EXPIRED: 'We couldn\'t verify your email because the request has expired. Please try to re-send the verification request.',
    INVALID: 'We couldn\'t verify your email because the token was invalid. Please try to re-send the verification request.',
    default: 'We were unable to verify your email address.',
};


export const EmailVerification = (): React$Node => {
    const { uid } = useParams();
    const history = useHistory();
    const { push: historyPush } = history;
    const [result, setResult] = useState(null);

    // Ensure a UID was passed in
    useEffect(() => {
        if (!uid) {
            historyPush('/');
        }
    }, [uid, historyPush]);

    const [
        requestTokenVerification,
        { data, loading },
    ] = useMutation(
        COMPLETE_EMAIL_VERIFICATION_MUTATION,
        { client: apiClient },
    );

    useEffect(() => {
        requestTokenVerification({ variables: { uid } });
    }, [requestTokenVerification, uid]);

    useEffect(() => {
        if (!data) {
            return;
        }

        const { completeEmailVerification } = data;
        const result = completeEmailVerification.result;

        if (result) {
            switch (result) {
                case 'VERIFIED':
                    toast.success('Email verified successfully');
                    historyPush("/signup/next");
                    break;
                default:
                    toast.error('Unable to verify email');
                    break;
            }
        }

        setResult(result);
    }, [data, historyPush, result]);

    return (
        loading
        ? <PreLoginCheckBase />
        : <Layout
            kind='auth'
            sidebar={null}
            content={
                    result && result !== 'VERIFIED'
                        ? <div className='c-auth__form'>
                            <div className='c-auth__form'>
                                <h1 className='c-auth__heading'>Email Verification Failed</h1>
                                <Notice type='error' icon='warning-fill' className='mb-4'>
                                    {result ? <p>{messages[result]}</p> : null}
                                </Notice>
                                <p>
                                    <Link to='/signup/next'>
                                        <Button kind='primary' className='c-auth__button'>Continue</Button>
                                    </Link>
                                </p>
                                {process.env.NODE_ENV === 'development'
                                    ? <DevRedirects historyPush={historyPush}/>
                                    : null
                                }
                            </div>
                        </div>
                        : null /* verified - so redirecting */
            }
        />
    );
}

function DevRedirects({ historyPush }) {
    const success = () => {
        toast.success("Dev says email verified")
        historyPush("/signup/next");
    }
    return (
        <Notice type="dev">
            <p>Dev only</p>
            <Button size={"sm"} onClick={success}>Fake successful verification</Button>
        </Notice>
    )
}