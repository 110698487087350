// @flow

import { useEffect } from 'react';
import { ANIMATION_DELAY_MS } from '../animation';

export function useAnimAutoFocus(autoFocus: ?boolean, disabled: ?boolean, focusSelectAll: boolean, inputRef: { current: ?HTMLElement }) {
    // using the inputs autofocus={true} attribute breaks the animations
    // somehow the browser overrides the transform() applied by framer to
    // bring the input fully into view immediately.
    // so we delay any autofocus by the animation time...
    useEffect(() => {
        if (autoFocus && !disabled) {
            setTimeout(() => {
                inputRef.current?.focus();
                if (focusSelectAll) {
                    inputRef.current?.select();
                }
            }, ANIMATION_DELAY_MS)
        }
        // we only do this on mount.
    }, [disabled, autoFocus, inputRef, focusSelectAll]);

}