// @flow

import { useEffect } from 'react';
import { usePendingCollaborations } from '../../hoc/Collaboration';
import { useEmailVerification } from '../../../hooks/useEmailVerification';
import { Button } from '../../element/Button';
import { RC_SUCCESS, RC_INITIAL, RC_CACHED, RC_API_REQUEST } from '../../../state/resource/type';
import { useResourceRoutes, history } from '../../../lib/history';
import { Notice } from '../../element/Notice';
import { request } from "../../../api/rest";
import { authUrl } from "../../../api/url";
import { useTwoFactorEditor } from "../../hoc/User";
import { TwoFactorEnableDialog } from "../user/edit/TwoFactorAuthentication";
import { useCurrentUser } from "../../hoc/lib";
import { TwoFactorBackupCodesDialog } from "../user/edit/TwoFactorBackupCodesDialog";
import { isAbleToAcceptCollaboration } from '../../../lib/user-collaborations';
import { Tooltip } from '../../element/Tooltip';
import {AcceptInviteTooltip} from '../user/AccountList';
import { SvgIcon } from '../../element/SvgIcon';


function useSignUpCollabs() {
    const getRoute = useResourceRoutes();
    const pending = usePendingCollaborations();
    const { collaborations, messages, cacheStatus } = pending;

    useEffect(() => {
        if (messages && messages.status === RC_SUCCESS && messages.resource) {
            if (messages.resource.status === 'accepted') {
                history.push(getRoute('dashboard', messages.resource?.account?.id));
            }
            messages.clear();
        }
    }, [messages, getRoute,]);

    useEffect(() => {
        if (cacheStatus === RC_CACHED && collaborations.length === 0) {
            history.push('/');
        }
    }, [cacheStatus, collaborations]);

    return {
        collab: collaborations.length ? collaborations[0] : null,
        ...pending,
    };
}

export const CollabSignUp = (): React$Node => {
    const user = useCurrentUser();
    const { collab, accept, messages, } = useSignUpCollabs();
    const {
        emailVerificationExpiry,
        isEmailVerificationRequestable,
        isEmailVerificationRequired,
        isQueryLoading,
        isUserEmailVerified,
        isUserTwoFactorEnabled,
        isVerificationLoading,
        queryError,
        requestEmailVerification,
    } = useEmailVerification();

    const isAccountTwoFactorRequired = collab ? collab.tfa_required : false;
    const isUserTwoFactorSetupRequired = isAccountTwoFactorRequired && !isUserTwoFactorEnabled;
    const isUserEmailVerificationRequired = isEmailVerificationRequired && !isUserEmailVerified;
    const isInvitationAcceptable = isAbleToAcceptCollaboration(user, collab);
    const isUserEmailVerificationCompleted = isEmailVerificationRequired && isUserEmailVerified;
    const isUserTwoFactorSetupCompleted = isAccountTwoFactorRequired && isUserTwoFactorEnabled;

    // If there are no pending collaborations and the redirect failed
    // then show nothing. `useSignUpCollabs` should redirect before now.
    if (!collab) {
        return (
            <div className="c-auth">{null}</div>
        );
    }

    return (<>
        <TwoFactorBackupCodesDialog userId={user?.id || ''} />
        <div className="c-auth">
            <div className="c-auth__form">

                {isQueryLoading && <div>Loading...</div>}
                {queryError && <div>Error: {queryError.message}</div>}

                <h1 className="c-auth__heading">Accept Invitation from {collab.inviter?.name}?</h1>

                <p className='mb-4'>
                    {collab.inviter?.name} has invited you to join the Brightbox account

                    {collab.account?.name
                        ?  <> <b>"{collab.account?.name}"</b>.</>
                        : <> with ID <b><code className='pl-1'>{collab.account?.id}</code></b>.</>
                    }
                </p>

                {!isInvitationAcceptable
                    ? <p className='mb-4'>Please follow the steps below to accept the invitation:</p>
                    : null
                }

                <ol className='c-auth__collab-checklist'>

                    {isEmailVerificationRequired
                        ? <EmailVerificationRequiredSection
                            emailVerificationExpiry={emailVerificationExpiry}
                            isEmailVerificationRequestable={isEmailVerificationRequestable}
                            isEmailVerificationRequired={isEmailVerificationRequired}
                            isQueryLoading={isQueryLoading}
                            isUserEmailVerified={isUserEmailVerified}
                            isVerificationLoading={isVerificationLoading}
                            requestEmailVerification={requestEmailVerification}
                            isUserEmailVerificationRequired={isUserEmailVerificationRequired}
                            isUserEmailVerificationCompleted={isUserEmailVerificationCompleted}
                            />
                        : null
                    }

                    {isAccountTwoFactorRequired
                        ? <TwoFactorRequiredSection
                            user={user}
                            isUserTwoFactorEnabled={isUserTwoFactorEnabled}
                            isUserTwoFactorSetupRequired={isUserTwoFactorSetupRequired}
                            isUserEmailVerificationRequired={isUserEmailVerificationRequired}
                            isUserTwoFactorSetupCompleted={isUserTwoFactorSetupCompleted}
                        />
                        : null
                    }

                    <li className={'c-auth__collab-checklist-item' + (isInvitationAcceptable ? ' c-auth__collab-checklist-item--active' : '')}>
                        <h2 className='c-auth__collab-checklist-heading'>Accept the invitation</h2>

                        {isInvitationAcceptable
                            ? <>
                                <p>Click the button below to accept the invitation and
                                    access the account.</p>
  
                            </>
                            : null
                        }

                              <Tooltip
                                    overlay={
                                        isInvitationAcceptable
                                            ? null
                                            : <AcceptInviteTooltip
                                                collab={collab}
                                                user={user}
                                            />
                                    }
                                >
                                    <Button
                                        kind='primary'
                                        type='submit'
                                        onClick={() => { accept(collab); }}
                                        disabled={messages?.status !== RC_INITIAL || !isInvitationAcceptable}
                                        state={messages?.status === RC_API_REQUEST ? 'loading' : ''}
                                        className='c-auth__button mt-4'
                                    >
                                        Accept Invitation
                                    </Button>
                                </Tooltip>

                    </li>

                </ol>

                {process.env.NODE_ENV === 'development'
                    ? <DevOnly
                        collab={collab}
                        isAccountTwoFactorRequired={isAccountTwoFactorRequired}
                        isUserTwoFactorEnabled={isUserTwoFactorEnabled}
                        isEmailVerificationRequired={isEmailVerificationRequired}
                        isUserEmailVerified={isUserEmailVerified}
                        isUserEmailVerificationRequired={isUserEmailVerificationRequired}
                        isUserTwoFactorSetupRequired={isUserTwoFactorSetupRequired}
                    />
                    : null
                }
            </div>
        </div>
    </>);
};

const EmailVerificationRequiredSection = ({
    isEmailVerificationRequestable,
    isQueryLoading,
    isUserEmailVerified,
    isVerificationLoading,
    requestEmailVerification,
    isUserEmailVerificationRequired,
    isUserEmailVerificationCompleted,
}) => {

    return (
        
        <li className={'c-auth__collab-checklist-item' 
                    + (isUserEmailVerificationRequired ? ' c-auth__collab-checklist-item--active' : '')
                    + (isUserEmailVerificationCompleted ? ' c-auth__collab-checklist-item--completed' : '')
                }
        >
            <h2 className='c-auth__collab-checklist-heading'>
                <span>Verify your email address</span>
                {isUserEmailVerificationCompleted
                        ? <SvgIcon svg={'tick'} className='ml-1 text-green-500' />
                        : null
                }
            </h2>
            {isUserEmailVerified
                ? null
                : <>
                
                {isEmailVerificationRequestable
                ? <>
                    <p>Please click the button below to re-send a verification link.</p>
                    <Button
                        size='sm'
                        kind='primary'
                        disabled={isQueryLoading || isVerificationLoading || !isEmailVerificationRequestable}
                        onClick={() => { requestEmailVerification(); }}
                    >
                    Resend Verification Email
                    </Button>
                    </>
                : <p>We've sent a verification link to your email address. Please click the link to verify your email address.</p>
  
                }
                </>
            }
        </li>
    );
};

const TwoFactorRequiredSection  = ({
    user,
    isUserTwoFactorEnabled,
    isUserTwoFactorSetupRequired,
    isUserEmailVerificationRequired,
    isUserTwoFactorSetupCompleted,
}) => {

    const twoFactorHook = useTwoFactorEditor(user?.id);

    return (
        <li className={'c-auth__collab-checklist-item' 
                        + (!isUserEmailVerificationRequired && isUserTwoFactorSetupRequired ? ' c-auth__collab-checklist-item--active' : '')
                        + (isUserTwoFactorSetupCompleted ? ' c-auth__collab-checklist-item--completed' : '')
                    }
        >
            <h2 className='c-auth__collab-checklist-heading'>
                <span>Setup Two-Factor Authentication</span>
                {isUserTwoFactorSetupCompleted
                    ? <SvgIcon svg={'tick'} className='ml-1 text-green-500' />
                    : null
                }
            </h2>

            {!isUserTwoFactorEnabled && !isUserEmailVerificationRequired && isUserTwoFactorSetupRequired
                ? <>         
                <p>This account requires all users to have Two-Factor Authentication, so you'll
                    need to set this up before accepting the invitation.</p>

                <Button
                    size='sm'
                    kind='primary'
                    onClick={() => twoFactorHook.enableDialog.show()}
                >
                    Start Two-Factor Setup
                </Button>
    
                <TwoFactorEnableDialog twoFactor={twoFactorHook} user={user} userLoading={user == null} />
                </>
                : null
            }  

        </li>
    );
}

const DevOnly = ({
    collab,
    isAccountTwoFactorRequired,
    isEmailVerificationRequestable,
    isEmailVerificationRequired,
    isUserEmailVerified,
    isUserTwoFactorEnabled,
    isUserEmailVerificationRequired,
    isUserTwoFactorSetupRequired,

}) => {
    const deleteCookies = async () => {
        await request({
            url: authUrl,
            method: 'DELETE',
        });
    }

    return (
        <Notice type="dev" className='mt-8'>
            <h4>Dev only</h4>
            <p>
                Collab: {collab.id}
                <br />
                User needs to verify email: {isUserEmailVerificationRequired ? 'Yes' : 'No'}
                <br />
                User needs to setup 2FA: {isUserTwoFactorSetupRequired ? 'Yes' : 'No'}
                <br />
                Account Two Factor Required: {isAccountTwoFactorRequired ? 'Yes' : 'No'}
                <br />
                User Two Factor Enabled: {isUserTwoFactorEnabled ? 'Yes' : 'No'}
                <br />
                Email Verification Required: {isEmailVerificationRequired ? 'Yes' : 'No'}
                <br />
                Email Verified: {isUserEmailVerified ? 'Yes' : 'No'}
                <br />
                Email Verification Requestable: {isEmailVerificationRequestable ? 'Yes' : 'No'}
            </p>
            <Button onClick={deleteCookies}>Delete Honcho auth</Button>
        </Notice>
    );
}
