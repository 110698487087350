// @flow
import { cn } from '../../element/lib/classNames';
import { Link } from 'react-router-dom';
import { SvgIcon } from '../../element/SvgIcon';
import { Tooltip } from '../../element/Tooltip';
import { Button } from '../../element/Button';
import { useResourceRoutes } from '../../../lib/history';

import type { OnboardingHook, } from '../../hoc/Onboarding';

type OnboardCheckListProps= {
    onboarding: OnboardingHook,
}

export const OnboardCheckList = ({ onboarding }: OnboardCheckListProps): React$Node => {
    const getRoute = useResourceRoutes();

    // prevent steps from slowly completing as resources are fetched
    if (onboarding.loading) return null;

    return (
        <div>
            <h4>Not sure where to start? 🙂</h4>

            <p className='text-gray-600 text-sm'>Here are a few suggestions to
                help you get started with Brightbox:</p>

            <ul className='c-checklist'>
            {onboarding.steps.map(step => (
                <li key={step.id} className={cn({
                    'c-checklist__item': true,
                    'c-checklist__item--completed': step.completed,
                    'c-checklist__item--tickable': step.link,
                })}>
                <Tooltip placement='left' overlay={step.link && !step.completed ? 'Mark as completed?' : null}>
                <div className='c-checklist__tick' onClick={!step.completed ? () => onboarding.onHideFlag(step.id) : null}>
                {step
                    ? <SvgIcon svg='tick' className='c-checklist__tick-icon' />
                    : null
                }
                </div>
                </Tooltip>
                {step.link && !step.completed
                    ? <>
                        <Link to={step.link} className='c-checklist__item-link'>
                            <div className='c-checklist__item-name'>
                                {step.title}
                            </div>
                            <SvgIcon
                                svg='caret-right'
                                className='c-checklist__item-arrow'
                            />
                        </Link>
                    </>
                    : <span className='c-checklist__item-name'>{step.title}</span>
                }
                </li>
            ))}
            </ul>
            <div className='mt-6 mb-1'>
            <Link to={getRoute('support')}>
                <Button size='sm' className='w-full'>Need help getting started?</Button>
            </Link>
            </div>
        </div>
    )
}