// @flow
import { SvgIcon } from './SvgIcon';
import { cn } from './lib/classNames';
import type { Icon } from '../../assets/icons';

type NoticeProps = {
    +children: React$Node,
    +icon?: Icon,
    +type?: 'info' | 'error' | 'warning' | 'dev',
    +size?: 'sm' | null;
    +className?: string,
}

export const Notice = ({ icon, children, type, size, className: extraClassName }: NoticeProps): React$Node => {
    let classes = {
        'c-notice': true,
        'c-notice--info': type === 'info',
        'c-notice--warning': type === 'warning',
        'c-notice--error': type === 'error',
        'c-notice--sm': size === 'sm',
        'c-notice--dev': type === 'dev',
    }

    const className = cn(classes) + ' ' + (extraClassName || '');

    return (
        <div className={className}>
            {icon ? <SvgIcon svg={icon} className='c-notice__icon'/> : null}
            <div className='c-notice__content'>
                {children}
            </div>
        </div>
    );
};