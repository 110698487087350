// @flow

import { useMemo, useState } from 'react';
import { useResourcesById } from './ListPage';
import { isShowDetailsResourceStatus } from '../element/Skeleton';
import { useResourceRoutes } from '../../lib/history';
import { useCurrentAccount, useCurrentUser, useCurrentAccountId } from './lib';
import { isEuVatCountry } from '../section/account/AccountDetails';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { add } from 'date-fns';
import Cookies from 'js-cookie';
import { SIGNUP_CREDIT_LABEL } from './Billing';
import { RC_ERROR } from '../../state/resource/type';

import type { BbCollaboration } from '../../api/type';
import type { BbServer } from '../../api/type.srv';
import type { AccountBillingData } from './Billing';

export type OnboardStep = {
    +id: string,
    +title: string,
    +completed: boolean,
    +link: string,
}

export type OnboardingHook = {
    +loading: boolean,
    +showWelcome: boolean,
    +showChecklist: boolean,
    +showTrial: boolean,
    +steps: $ReadOnlyArray<OnboardStep>,
    +trialDaysRemaining: number,
    +onHideFlag: (flag: string) => void,
}

export const F_WELCOME = 'welcome';
export const F_CHECKLIST = 'checklist';
export const F_SIGNUP = 'signup';
export const F_BILLING = 'billing';
export const F_VERIFICATION = 'verification';
export const F_SERVER = 'server';
export const F_SSH_KEY = 'ssh_key';
export const F_COLLAB = 'collab';
export const F_ADDRESS = 'address';
export const F_EU_VAT = 'eu_vat';

const useCookieFlags = () => {
    const accountId = useCurrentAccountId() || '';
    const cookieName = 'onboarding-' + accountId;
    const [flags, setFlags] = useState<Set<string>>((): Set<string> => {
        const raw: ?string = Cookies.get(cookieName);

        return raw ? new Set(raw.split(',')) : new Set();
    });
    
    return {
        flags,
        setFlag: (flag: string) => {
            const nextFlags = (new Set(flags)).add(flag);
            setFlags(nextFlags);
            Cookies.set(cookieName, Array.from(nextFlags.values()).join(','));
        }
    }
}

export function useOnboarding(data: ?AccountBillingData): OnboardingHook {
    const { flags, setFlag } = useCookieFlags();
    const { cacheStatus: teamStatus, resources: teamMembers } = useResourcesById<BbCollaboration>('collaboration');
    const { cacheStatus: serverStatus, resources: servers } = useResourcesById<BbServer>('server');
    const getRoute = useResourceRoutes();
    const account = useCurrentAccount();
    const user = useCurrentUser();

    const steps: Array<OnboardStep> = [
        {
            id: F_BILLING,
            title: 'Add billing details',
            completed: !!account && account.valid_credit_card,
            link: getRoute('card'),
        },
        {
            id: F_VERIFICATION,
            title: 'Verify your email address',
            completed: !!user && user.email_verified,
        },
        {
            id: F_SSH_KEY,
            title: 'Add your SSH key',
            completed: flags.has(F_SSH_KEY) || (!!user && user.ssh_key !== null && user.ssh_key !== ''),
            link: getRoute('user', 'ssh_key'),
        },
        {
            id: F_SERVER,
            title: 'Create a Server',
            completed: flags.has(F_SERVER) || ((isShowDetailsResourceStatus(serverStatus)) && Object.keys(servers).length > 0),
            link: getRoute('server', 'add'),
        },
        {
            id: F_COLLAB,
            title: 'Invite a Team Member',
            completed: flags.has(F_COLLAB) || ((isShowDetailsResourceStatus(teamStatus)) && Object.keys(teamMembers).length > 0),
            link: getRoute('collaboration', 'add'),
        },
        {
            id: F_ADDRESS,
            title: 'Add your Billing Address',
            completed: flags.has(F_ADDRESS) || (!!account && account.address_1 !== ''),
            link: getRoute('account', 'settings/address'),
        },
    ];

    if (account && isEuVatCountry(account.country_code)) {
        steps.push({
            id: F_EU_VAT,
            title: 'Add your EU VAT number',
            completed: flags.has(F_EU_VAT) || (!!account && account.vat_registration_number !== null && account.vat_registration_number !== ''),
            link: getRoute('account', 'settings/vat_registration_number'),
        });
    }

    steps.sort((a: OnboardStep, b: OnboardStep) => {
        if (a.completed === b.completed) return 0;
        if (a.completed) return -1;
        return 1;
    });

    const showTrial = useMemo((): boolean => {
        return !!(data?.account?.billingActivity?.categories?.find(
            x => x.name === 'Discounts' && !!x.items.find(i => i.label === SIGNUP_CREDIT_LABEL))
        );
    }, [data])

    const now = new Date();

    let trialDaysRemaining = account
        ? differenceInCalendarDays(add(account.created_at, { months: 1 }), now)
        : 0;

    const serversReady = isShowDetailsResourceStatus(serverStatus) || (serverStatus === RC_ERROR && account?.status === 'pending');
    const teamReady = isShowDetailsResourceStatus(teamStatus) || (teamStatus === RC_ERROR && account?.status === 'pending');


    return {
        showWelcome: trialDaysRemaining > 0 && !flags.has(F_WELCOME),
        showChecklist: trialDaysRemaining > 0 && !flags.has(F_CHECKLIST),
        loading:
            !serversReady
            || !teamReady
            || account === null
            || user === null
        ,
        steps,
        showTrial,
        trialDaysRemaining,
        onHideFlag: (flag: string) => {
            setFlag(flag);
        }
    };
}
